
import { defineComponent } from "vue";
import SpeakWithUs from "@/api/models/SpeakWithUs";
import api from "@/api";
import { recaptchaConst } from "@/constants/recaptcha";

export default defineComponent({
	name: "ContactView",
	components: {},
	data() {
		return {
			siteKey: recaptchaConst.siteKey,
			enquiries: [
				// {
				// 	title: "Number",
				// 	line1: "For any enquiries: +60173573988",
				// 	line2: "Message us on WhatsApp"
				// },
				{
					title: "Email",
					line1: "Enquiries: customercare@howsmate.com.my",

					line2: "Partnerships: partners@howsmate.com.my"
				}
				// {
				// 	title: "Address",
				// 	line1: "Level 3, KL Gateway Mall, No. 2, Jalan",
				// 	line2: "Kerinchi, Pantai Dalam, 59200 Kuala Lumpur"
				// }
			],
			textFields: [
				{
					title: "name",
					placeholder: "Name"
				},
				{
					title: "email",
					placeholder: "Email"
				},
				{
					title: "message",
					placeholder: "Message"
				}
			],
			centers: [
				{
					title: "Early Registration",
					line1: "Register with us early to receive special account that allows up to 5 free listing slots.",
					line2: "Registration will open on 1st September 2022, 10:00 am."
				},
				{
					title: "List with us",
					line1: "Choosing Howsmate as your listing partner?",
					line2: "Speak to our team to about how your organisation at "
				},
				{
					title: "Student Hostel",
					line1: "We welcome student accomodation service providers to join us.",
					line2: `Reach more students as we join hands. \nEmail us for more info at `
				},
				{
					title: "Investor Relations",
					line1: "We are expected to expand to 5 new countries by 2025.",
					line2: `For investor relation inquiries or media requests, \ncontact us at `
				}
			]
		};
	},
	methods: {
		submitSpeakWithUs() {
			const form = this.$refs.speakForm as any;
			const speakForm = new SpeakWithUs(
				form.name.value,
				form.email.value,
				form.message.value
			);
			api.postSpeakWithUs(speakForm);
		}
	}
});
