import {
	HomeView,
	InfoView,
	SupportView,
	ContactView,
	ExploreView,
} from '../views';

export default [
	{
		path: '/',
		name: 'home',
		meta: { requiresAuth: false },
		component: HomeView,
	},
	{
		path: '/info',
		name: 'info',
		meta: { requiresAuth: false },
		component: InfoView,
	},
	{
		path: '/support',
		name: 'support',
		meta: { requiresAuth: false },
		component: SupportView,
	},
	{
		path: '/contact',
		name: 'contact',
		meta: { requiresAuth: false },
		component: ContactView,
	},
	{
		path: '/explore',
		name: 'explore',
		meta: { requiresAuth: false },
		component: ExploreView,
	},
];
