import { defineStore } from "pinia";

export const useSignupStore = defineStore({
    id: "signupStore",
    state: () => ({
        email: '',
        pass: ''
    }),
    getters: {
        hasData: (state) => !(state.email === '' && state.pass === '')
    },
    actions: {
        set(email:string, pass:string) {
            this.email = email;
            this.pass = pass;
        }
    }
});