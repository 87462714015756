import { defineStore } from "pinia";
import type { RoomModel } from "@/models/property-form/RoomModel";
import type { BillModel } from "@/models/property-form/BillModel";
import type { PromptModel } from "@/models/property-form/PromptModel";
import api from "@/api";
import { usePropConstStore } from "@/stores/propertyConstStore";
import rooms from "@/constants/rooms";
import { joinName } from "../helper/Utilities";
import { useGlobalStore } from "./globalStore";
import { useAuthStore } from "@/stores/authStore";
import { formatISODate } from "@/helper/Utilities";
import PropertyForm from "@/api/models/PropertyForm";
import { LandmarkModel } from "@/models/property-form/LandmarkModel";

export const usePropListingStore = defineStore({
	id: "propertyListingStore",
	state: () => ({
		isValid: false,
		isEdit: false,
		id: 0,
		currentActive: 0,
		property: {
			type: "Flat",
			address: {
				id: 0,
				buildingName: "",
				streetAddress1: "",
				streetAddress2: "",
				postcode: "",
				city: ""
			},
			advArea: {
				id: 0,
				state: "Kuala Lumpur",
				area: "Kuala Lumpur"
			},
			title: "",
			numRooms: 1,
			numBathrooms: 1,
			numHousemates: 0
		},
		availability: {
			numRooms: "1 room",
			date: formatISODate(new Date()),
			duration: "6 months",
			rooms: [
				{
					id: 0,
					name: "",
					type: "Shared",
					bed: "Single",
					bathroom: "Ensuite",
					furnishing: "Unfurnished",
					roomFeatures: [] as string[]
				}
			] as RoomModel[]
		},
		rent: {
			rentValue: "",
			deposit: "",
			otherBills: [] as BillModel[],
			billIncluded: [] as string[]
		},
		features: {
			description: "",
			placeFeatures: [] as string[],
			generalFeatures: [] as string[],
			recreationFeatures: [] as string[],
			whatsNearby: [] as any[],
			prompts: [] as PromptModel[]
		},
		preference: {
			accepting: [] as string[],
			preference: [] as string[]
		},
		photo: [] as (File | string)[],
		user: {
			name: "",
			mobile: "",
			avatar: "",
			id: "" as string
		}
	}),
	getters: {
		getCurrentActive: (state) => state.currentActive
	},
	actions: {
		isValid() {
			return new Promise<boolean>((resolve) => {
				if (this.$state.isValid) resolve(true);
				resolve(false);
			});
		},
		checkEmpty(obj: any) {
			for (const key in obj) {
				if (obj[key] instanceof Object) {
					if (!this.checkEmpty(obj[key])) return false;
				} else {
					if (obj[key].length !== 0) return false;
				}
			}
			return true;
		},
		async uploadPhotos() {
			const newPhotos = this.photo.filter((p) => p instanceof File);

			if (newPhotos.length > 0)
				return await api.postPropertyPhoto(newPhotos as File[]);
			else return [];
		},
		validateForm() {
			const property = this.$state.property;
			const availability = this.$state.availability;
			const rent = this.$state.rent;
			const photos = this.$state.photo;

			const isValidProperty =
				(property.title +
					property.address.streetAddress1 +
					property.address.postcode,
				property.address.city) &&
				property.numRooms +
					property.numBathrooms +
					property.numHousemates >=
					2;
			const isValidAvailability =
				(
					availability.duration +
					availability.date +
					availability.numRooms
				).length > 0 &&
				availability.rooms.length >= 1 &&
				availability.rooms.every(
					(room) =>
						(room.bathroom + room.bed + room.furnishing + room.type)
							.length > 0
				);
			const isValidRent = rent.rentValue.length > 0;
			const isValidPhotos = photos.length >= 1;

			return (
				isValidProperty &&
				isValidAvailability &&
				isValidRent &&
				isValidPhotos
			);
		},
		async buildJson(id?: number) {
			const propConst = usePropConstStore();
			useGlobalStore().setTotalPercent(14 + this.photo.length);
			await propConst.loadAll();

			const user = (await api.getCurrentUser().finally(() => {
				useGlobalStore().setPercent(1);
			})) as any;

			const newPhotoIds = await this.uploadPhotos().then((newIds) => {
				const existingPhotos = this.photo.filter(
					(p) => typeof p === "string"
				);
				return [...existingPhotos, ...newIds];
			});

			const typeId = propConst.getId(
				propConst.propTypes,
				this.property.type
			);
			const numOfRoom = this.property.numRooms;
			const numOfBathroom = this.property.numBathrooms;
			const numOfHousemate = this.property.numHousemates;
			const numOfAvailableRoom = rooms.amount.indexOf(
				this.availability.numRooms
			);

			const advAreaId = propConst.getId(
				propConst.areas,
				this.property.advArea.area
			);
			const durationStayId = propConst.getId(
				propConst.durationOfStay,
				this.availability.duration
			);
			const availDate = new Date(this.availability.date).toISOString();

			const avRooms = this.availability.rooms.map((x, i) => ({
				Room_id: {
					Order: i + 1,
					Name: x.name,
					Type: propConst.getId(propConst.roomType, x.type),
					Bed: propConst.getId(propConst.roomBed, x.bed),
					Bath: propConst.getId(propConst.roomBath, x.bathroom),
					Furnishing: propConst.getId(
						propConst.roomFurnishing,
						x.furnishing
					),
					Feature: {
						create: x.roomFeatures.map((y) => ({
							Room_id: "+",
							RoomFeature_id: {
								id: propConst.getId(propConst.roomFeature, y)
							}
						})),
						update: [],
						delete: []
					}
				}
			}));

			const otherBills = this.rent.otherBills.map((x) => ({
				Bill_id: {
					Title: x.title,
					Frequency: x.frequency,
					Amount: parseInt(x.value)
				}
			}));

			const billIncluded = this.rent.billIncluded.map((x) => ({
				Property_id: "+",
				BillIncluded_id: {
					id: propConst.getId(propConst.billIncluded, x)
				}
			}));

			const placeFeatures = this.features.placeFeatures.map((x) => ({
				Property_id: "+",
				PlaceFeature_id: {
					id: propConst.getId(propConst.placeFeatures, x)
				}
			}));
			const whatsNearby = this.features.whatsNearby.map((x) => ({
				Property_id: "+",
				Landmark_id: {
					Title: x.title,
					Mode: x.mode,
					Duration: parseInt(x.duration),
					Distance: parseInt(x.distance),
					Landmark_id: x.landmark
				}
			}));

			const facAms = this.features.generalFeatures
				.concat(this.features.recreationFeatures)
				.map((x) => ({
					Property_id: "+",
					FacilityAmenity_id: {
						id: propConst.getId(propConst.facilityAmenity, x)
					}
				}));

			const prompts = this.features.prompts.map((x) => ({
				Prompt_id: {
					Title: propConst.getId(propConst.promptTitle, x.title),
					Text: x.value
				}
			}));

			const accepting = this.preference.accepting.map((x) => ({
				Property_id: "+",
				PreferenceAccept_id: {
					id: propConst.getId(propConst.prefAccept, x)
				}
			}));
			const preference = this.preference.preference.map((x) => ({
				Property_id: "+",
				PreferenceAccept_id: {
					id: propConst.getId(propConst.prefAccept, x)
				}
			}));

			const photos = newPhotoIds.map((x, index) => ({
				PropertyPhoto_id: {
					Name: x,
					Photo: x,
					Order: index + 1
				}
			}));

			const active = () => {
				const activeLimit = useAuthStore().getActiveLimit;
				const currentActive = usePropListingStore().getCurrentActive;

				if (currentActive < activeLimit) return true;
				else return false;
			};
			const body = new PropertyForm({
				User: user.data.data.id,
				Type: typeId,
				Address: {
					BuildingName: this.property.address.buildingName,
					StreetAddress1: this.property.address.streetAddress1,
					StreetAddress2: this.property.address.streetAddress2,
					Postcode: this.property.address.postcode,
					City: this.property.address.city,
					State: this.property.advArea.state
				},
				Title: this.property.title,
				NumberOfRoom: numOfRoom,
				NumberOfBathroom: numOfBathroom,
				NumberOfHousemate: numOfHousemate,
				AdvertisedArea: advAreaId,
				AvailableRoom: numOfAvailableRoom,
				AvailableDate: availDate,
				DurationOfStay: durationStayId,
				Room: {
					create: avRooms,
					update: [],
					delete: []
				},
				Rent:
					this.rent.rentValue.length < 1 ? "0" : this.rent.rentValue,
				Deposit: this.rent.deposit.length < 1 ? "0" : this.rent.deposit,
				OtherBill: {
					create: otherBills,
					update: [],
					delete: []
				},
				BillIncluded: {
					create: billIncluded,
					update: [],
					delete: []
				},
				PlaceFeatures: {
					create: placeFeatures,
					update: [],
					delete: []
				},
				FacilitiesAmenities: {
					create: facAms,
					update: [],
					delete: []
				},
				Description: this.features.description.replace(
					/(?:\r\n|\r|\n)/g,
					"\\n"
				),
				Prompts: {
					create: prompts,
					update: [],
					delete: []
				},
				Landmark: {
					create: whatsNearby,
					update: [],
					delete: []
				},
				Accepting: {
					create: [accepting, preference].flat(),
					update: [],
					delete: []
				},
				Photos: {
					create: photos,
					update: [],
					delete: []
				},
				Active: active()
			});
			const json = id ? JSON.stringify({ id, ...body }) : body.ToJson();

			return await api
				.postPropertyForm(json)
				.then((res) => {
					useGlobalStore().setPercent(1);
					return res;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		clear() {
			localStorage.removeItem("propertyListingStore");
			this.$reset();
		},
		async getPropertyById(id: number) {
			this.clear();
			const response = await api.getPropertyListingById(id);
			const prop = response.data.data;

			this.$state.id = prop.id;
			// user
			this.$state.user.name = joinName(
				prop.User.first_name,
				prop.User.last_name
			);
			this.$state.user.mobile = prop.User.mobile_number;
			this.$state.user.avatar = prop.User.avatar;
			this.$state.user.id = prop.User.id;

			// property
			this.$state.property.type = prop.Type.DisplayName;
			this.$state.property.title = prop.Title;
			this.$state.property.numRooms = prop.NumberOfRoom;
			this.$state.property.numBathrooms = prop.NumberOfBathroom;
			this.$state.property.numHousemates = prop.NumberOfHousemate;
			this.$state.property.address.id = prop.Address.id;
			this.$state.property.address.buildingName =
				prop.Address.BuildingName;
			this.$state.property.address.streetAddress1 =
				prop.Address.StreetAddress1;
			this.$state.property.address.streetAddress2 =
				prop.Address.StreetAddress2;
			this.$state.property.address.city = prop.Address.City;
			this.$state.property.address.postcode = prop.Address.Postcode;
			this.$state.property.advArea.id = prop.AdvertisedArea.id;
			this.$state.property.advArea.area = prop.AdvertisedArea.Name;
			this.$state.property.advArea.state = prop.AdvertisedArea.State.Name;

			// availability
			this.$state.availability.numRooms =
				rooms.amount[prop.AvailableRoom];
			const date = new Date(prop.AvailableDate);
			this.$state.availability.date = `${date.getFullYear()}-${
				date.getMonth() + 1
			}-${date.getDate()}`;
			this.$state.availability.duration = prop.DurationOfStay.DisplayName;
			this.$state.availability.rooms = prop.Room.map((x: any) => ({
				id: x.id,
				name: x.Room_id.Name,
				type: x.Room_id.Type.DisplayName,
				bed: x.Room_id.Bed.DisplayName,
				bathroom: x.Room_id.Bath.DisplayName,
				furnishing: x.Room_id.Furnishing.DisplayName,
				roomFeatures: x.Room_id.Feature.map(
					(y: any) => y.RoomFeature_id.DisplayName
				)
			}));

			// features
			this.$state.features.description = prop.Description;
			this.$state.features.placeFeatures = prop.PlaceFeatures.map(
				(x: any) => x.PlaceFeature_id.DisplayName
			);
			this.$state.features.generalFeatures =
				prop.FacilitiesAmenities.filter(
					(x: any) => x.FacilityAmenity_id.Type === "general"
				).map((x: any) => x.FacilityAmenity_id.DisplayName);
			this.$state.features.recreationFeatures =
				prop.FacilitiesAmenities.filter(
					(x: any) =>
						x.FacilityAmenity_id.Type === "sports-and-recreation"
				).map((x: any) => x.FacilityAmenity_id.DisplayName);
			this.$state.features.whatsNearby = prop.Landmark.map((x: any) => ({
				title: x.Landmark_id.Title,
				mode: x.Landmark_id.Mode,
				duration: x.Landmark_id.Duration.toString(),
				distance: x.Landmark_id.Distance.toString(),
				landmark: x.Landmark_id.Landmark_id
			}));
			this.$state.features.prompts = prop.Prompts.map((x: any) => ({
				title: x.Prompt_id.Title.DisplayName,
				value: x.Prompt_id.Text,
				description: "",
				show: x.Prompt_id.Title.Show
			}));

			// rent
			this.$state.rent.rentValue = prop.Rent.toString();
			this.$state.rent.deposit = prop.Deposit.toString();
			this.$state.rent.billIncluded = prop.BillIncluded.map(
				(x: any) => x.BillIncluded_id.DisplayName
			);
			this.$state.rent.otherBills = prop.OtherBill.map((x: any) => ({
				id: x.id,
				Bill_id: x.Bill_id.id,
				title: x.Bill_id.Title,
				frequency: x.Bill_id.Frequency,
				value: x.Bill_id.Amount
			}));

			// preference
			this.$state.preference.accepting = prop.Accepting.filter(
				(x: any) => x.type === "Accept"
			).map((x: any) => x.PreferenceAccept_id.DisplayName);
			this.$state.preference.preference = prop.Accepting.filter(
				(x: any) => x.type === "Preference"
			).map((x: any) => x.PreferenceAccept_id.DisplayName);

			// photo
			this.$state.photo = prop.Photos.sort(
				(x: any, y: any) =>
					x.PropertyPhoto_id.Order - y.PropertyPhoto_id.Order
			).map((x: any) => x.PropertyPhoto_id.Photo);

			this.$state.isValid = true;
		},
		async getActiveProperties() {
			const properties = await api.getUserPropertyListing();

			const activeProperties: any[] = properties.data.data.filter(
				(property: any) => property.Active
			);
			this.currentActive = activeProperties.length;
			return activeProperties;
		},
		async deletePropertyById(id: number, noClear?: boolean) {
			await api.deletePropertyById(id).finally(() => {
				if (!noClear) this.clear();
			});
		},
		async updateActive(id: number | number[], value: boolean) {
			if (Array.isArray(id)) {
				const body = {
					key: id,
					data: {
						Active: value
					}
				};
				await api.patchProperties(body);
			} else {
				const body = {
					Active: value
				};
				await api.patchPropertyById(id, body);
			}
		}
	},
	persist: {
		paths: [
			"isEdit",
			"id",
			"property",
			"availability",
			"rent",
			"features",
			"preference",
			"photo",
			"user",
			"currentActive"
		]
	}
});
