export const pricing_perk_infos = [
	{ title: "", description: "Listing size of your ad" },
	{
		title: "Search ranking placement",
		description: "The page ranking of your listing in the search results."
	},
	{
		title: "Number of Property Listings",
		description:
			"The number of property listings that you can post in this plan."
	},
	{
		title: "Appears on tops of the search result for your plan.",
		description:
			" The number of days until your listing gets rotated to the front of the page ranking in the search results."
	},
	{
		title: "Message freely",
		description:
			"Message anyone on Howsmate chat. New free account can message freely after 10 days of being online consecutively. Or upgrade your plan to message anyone immediately."
	},
	{
		title: "View number",
		description:
			"View available housemate ad numbers. Property listing numbers are free to see."
	},
	{
		title: "Unlock more features",
		description:
			"Use new features like appointment features to speed up your interaction with people looking at your property ad."
	},
	{
		title: "Additional property listings",
		description: "Add more active property listing"
	}
];
