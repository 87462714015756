import { defineStore } from "pinia";
import { useCookies } from "vue3-cookies";
import api from "@/api";
import { AccountProvider } from "@/enums/AccountProvider";
import { plans } from "@/constants";
import { usePropListingStore } from "./propertyListingStore";

export const useAuthStore = defineStore({
	id: "authStore",
	state: () => ({
		user: {
			id: "",
			username: "",
			firstName: "",
			lastName: "",
			role: "",
			phone: "",
			email: "",
			age: "",
			gender: "",
			avatar: "",
			createdAt: "",
			accountType: "Free",
			stripeCustomerID: "",
			emailConfirmation: false,
			mobileConfirmation: false,
			provider: AccountProvider.Default,
			emailNotifications: false
		},
		access_token: "",
		refresh_token: "",
		expires: 0,
		tokenExpire: new Date(),
		loading: false,
		show: {
			email: false,
			phone: false
		}
	}),
	getters: {
		// TODO check token expiry and refresh
		isLoggedIn: (state) =>
			!(!state.access_token || state.access_token.length === 0),
		isEmpty: (state) => state.user.id === "",
		// check token expiry
		isValid: () => true,
		getPlanName: (state) => state.user.accountType,
		getActiveLimit: (state) => {
			const activeLimit =
				plans.find((plan) => state.user.accountType.includes(plan.type))
					?.activeLimit ?? 1;
			return activeLimit;
		}
	},
	actions: {
		clear() {
			localStorage.removeItem("authStore");
			this.$reset();
		},
		setTokens(
			access_token: string,
			refresh_token: string,
			expires: number
		) {
			this.access_token = access_token;
			this.refresh_token = refresh_token;
			this.expires = expires;

			const expiryDate = new Date();
			expiryDate.setMinutes(new Date().getMinutes() + expires);
			this.tokenExpire = expiryDate;

			const cookiesStore = useCookies();
			cookiesStore.cookies.set("accessToken", this.access_token);
		},
		syncTokens() {
			const cookiesStore = useCookies();
			this.access_token = cookiesStore.cookies.get("accessToken");
		},
		clearTokens() {
			const cookiesStore = useCookies();
			cookiesStore.cookies.remove("accessToken");
		},
		async refreshUser() {
			await api.postRefreshToken();
			const user = await api.getCurrentUser();
			this.$state.user.firstName = user.data.data.first_name ?? "";
			this.$state.user.lastName = user.data.data.last_name ?? "";
			this.$state.user.id = user.data.data.id;
			this.$state.user.email = user.data.data.email;
			this.$state.user.role = user.data.data.role;
			this.$state.user.phone = user.data.data.mobile_number;
			this.$state.user.age = user.data.data.age ?? "";
			this.$state.user.gender = user.data.data.gender ?? "";
			this.$state.user.avatar = user.data.data.avatar;
			this.$state.user.createdAt = "";
			this.$state.user.username =
				this.$state.user.firstName + " " + this.$state.user.lastName;
			if (user.data.data.current_plan)
				this.$state.user.accountType = user.data.data.current_plan;
			if (user.data.data.provider !== AccountProvider.Default)
				this.$state.user.emailConfirmation = true;
			else
				this.$state.user.emailConfirmation =
					user.data.data.email_confirmation;

			this.$state.user.mobileConfirmation =
				user.data.data.mobile_confirmation;
			this.$state.user.emailNotifications =
				user.data.data.email_notifications;

			// if (user.data.data.provider === AccountProvider.Google)
			this.$state.user.provider = user.data.data.provider;
			// else if (user.data.data.provider === AccountProvider.Facebook)
			// 	this.$state.user.provider = AccountProvider.Facebook;
			const activeListings =
				await usePropListingStore().getActiveProperties();
			const activeLimit = this.getActiveLimit;
			if (activeListings.length > activeLimit) {
				const keys = activeListings
					.slice(activeLimit)
					.map((listing: any) => listing.id);
				if (keys.length > 0)
					await usePropListingStore().updateActive(keys, false);
			}
		},
		async uploadPhotos(avatar: File) {
			return await api.postAvatar(avatar);
		},
		updateUser(body: any) {
			return api.patchCurrentUser(this.$state.user.id, body);
		},
		setShow(type: "email" | "phone", show: boolean) {
			this.$state.show[type] = show;
		}
	},
	persist: { paths: ["user", "show"] }
});
