import { usePropListingStore } from "@/stores/propertyListingStore";
import {
	PropertyLists,
	HousemateLists,
	PropertyListingView,
	HousemateListingView
} from "../views";
import { RouteLocationNormalized } from "vue-router";
import { useHousemateFormStore } from "@/stores/housemateFormStore";

export default [
	{
		path: "/property/:id",
		name: "property-detail",
		meta: { requiresAuth: false },
		component: PropertyListingView,
		beforeEnter: async (to: RouteLocationNormalized) => {
			if (to.params.id == "preview") {
				const { photo, validateForm } = usePropListingStore();
				if (photo.length < 5) {
					alert("Minimum of 5 photos required");
					return false;
				}
				if (!validateForm()) {
					alert(
						"Oops, There are some field that needs to be filled. Can you check if you have filled up the: Listing title, Address, Rent and Deposit Amount."
					);

					return false;
				}
			} else return true;
		}
	},
	{
		path: "/property",
		name: "property-lists",
		meta: { requiresAuth: false },
		component: PropertyLists
	},

	{
		path: "/housemate/:id",
		name: "housemate-detail",
		meta: { requiresAuth: false },
		component: HousemateListingView,
		beforeEnter: async (to: RouteLocationNormalized) => {
			if (to.params.id !== "preview") return true;

			const { validateForm, photo } = useHousemateFormStore();
			if (validateForm()) return true;
			if (!photo) {
				alert("Please insert a photo for your housemate profile.");
			} else
				alert(
					"Oops, There are some field that needs to be filled. Can you check if you have filled up the: Name, Age, Maximum budget and Looking at."
				);
			return false;
		}
	},
	{
		path: "/housemate",
		name: "housemate-lists",
		meta: { requiresAuth: false },
		component: HousemateLists
	}
];
