import BaseModel from "@/api/models/BaseModel";
export type PropertyAddress = {
	BuildingName: string; //optional
	StreetAddress1: string;
	StreetAddress2: string; //optional
	Postcode: string;
	City: string;
	State: string;
};
export type PropertyRelational = {
	create: any[];
	update: any[];
	delete: any[];
};
type PropertyFormBody = {
	id?: number;
	User: string;
	Type: number;
	Address: PropertyAddress;
	Title: string;
	NumberOfRoom: number;
	NumberOfBathroom: number;
	NumberOfHousemate: number;
	AdvertisedArea: number;
	AvailableRoom: number;
	AvailableDate: number | string;
	DurationOfStay: number;
	Room: PropertyRelational; //Room feature optional
	Rent: string;
	Deposit: string; //optional
	OtherBill: PropertyRelational; //optional
	BillIncluded: PropertyRelational; //optional
	PlaceFeatures: PropertyRelational; //optional
	FacilitiesAmenities: PropertyRelational; //both optional
	Description: string; //optional
	Prompts: PropertyRelational; //optional value
	Landmark: PropertyRelational; //optional value
	Accepting: PropertyRelational; //optional
	Photos: PropertyRelational;
	Active: boolean;
};
export default class PropertyForm extends BaseModel {
	User: string;
	Type: number;
	Address: PropertyAddress;
	Title: string;
	NumberOfRoom: number;
	NumberOfBathroom: number;
	NumberOfHousemate: number;
	AdvertisedArea: number;
	AvailableRoom: number;
	AvailableDate: number | string;
	DurationOfStay: number;
	Room: PropertyRelational; //Room feature optional
	Rent: string;
	Deposit: string; //optional
	OtherBill: PropertyRelational; //optional
	BillIncluded: PropertyRelational; //optional
	PlaceFeatures: PropertyRelational; //optional
	FacilitiesAmenities: PropertyRelational; //both optional
	Description: string; //optional
	Prompts: PropertyRelational; //optional value
	Landmark: PropertyRelational; //optional value
	Accepting: PropertyRelational; //optional
	Photos: PropertyRelational;
	Active: boolean;

	constructor(body: PropertyFormBody) {
		super();
		this.User = body.User;
		this.Type = body.Type;
		this.Address = body.Address;
		this.Title = body.Title;
		this.NumberOfRoom = body.NumberOfRoom;
		this.NumberOfBathroom = body.NumberOfBathroom;
		this.NumberOfHousemate = body.NumberOfHousemate;
		this.AdvertisedArea = body.AdvertisedArea;
		this.AvailableRoom = body.AvailableRoom;
		this.AvailableDate = body.AvailableDate;
		this.DurationOfStay = body.DurationOfStay;
		this.Room = body.Room;
		this.Rent = body.Rent;
		this.Deposit = body.Deposit;
		this.OtherBill = body.OtherBill;
		this.BillIncluded = body.BillIncluded;
		this.PlaceFeatures = body.PlaceFeatures;
		this.FacilitiesAmenities = body.FacilitiesAmenities;
		this.Description = body.Description;
		this.Prompts = body.Prompts;
		this.Landmark = body.Landmark;
		this.Accepting = body.Accepting;
		this.Photos = body.Photos;
		this.Active = body.Active;
	}
}
