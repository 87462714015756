import api from "@/api";
import Login from "@/api/models/Login";
import Signup from "@/api/models/Signup";
import { UserSaveModel } from "@/api/models/UserModel";
import { useAuthStore } from "@/stores/authStore";
import { from } from "linq-to-typescript";
import def from "@/constants/default";

type SignupInputs = {
	firstName: string;
	lastName: string;
	email?: string;
	password?: string;
	bod: string[];
};
type SignupOptions = {
	emailNotification?: boolean;
	ssoSignUp?: boolean;
};

export async function submitForm(
	inputs: SignupInputs,
	options?: SignupOptions
) {
	if (isLegalAge(inputs.bod)) {
		if (options?.ssoSignUp) {
			await sso(
				inputs.firstName,
				inputs.lastName,
				options.emailNotification ?? false
			);
			return "/signup/otp";
		} else if (validateForm(inputs)) {
			await signup(inputs, options?.emailNotification ?? false);
			await login(inputs.email, inputs.password);
			return "/login";
		} else throw new Error("Invalid input(s). Please try again.");
	} else throw new Error("Invalid input(s). Please try again.");
}

export async function signup(inputs: SignupInputs, emailNotification: boolean) {
	if (inputs.email && inputs.password) {
		const signupForm = new Signup(
			inputs.firstName,
			inputs.lastName,
			inputs.email,
			inputs.password,
			!emailNotification
		);
		await api.postSignup(signupForm).catch((r) => {
			const errors = from(r.response.data.errors)
				.select(
					(x: any) =>
						`${
							x.message.includes("unique")
								? "Your email already has an account with us, try clicking on the “Profile” button on the top right to continue."
								: x.message
						}`
				)
				.toArray();
			throw new Error(errors.join("\n"));
		});
	}
}
export async function login(email?: string, password?: string) {
	if (email && password) {
		const loginForm = new Login(email, password);
		await api.postLogin(loginForm).catch((r) => {
			const errors = from(r.response.data.errors)
				.select((x: any) => `${x.message}`)
				.toArray();
			throw new Error(errors.join("\n"));
		});
	}
}
export async function sso(
	firstName: string,
	lastName: string,
	emailNotification: boolean
) {
	const authStore = useAuthStore();
	const userSave = new UserSaveModel(firstName, lastName, emailNotification);
	await authStore.updateUser(userSave);
}
/* 
Password rules
- must not contain any whitespace
- must contain at least one Uppercase character
- must contain at least one Lowercase character
- must contain at least one digit
- must have at least one Special Symbol
- must be at least 12 characters long
*/
export function validateForm(inputs: SignupInputs) {
	const noEmpty = Object.values(inputs).every((x) => x.length > 0);
	if (inputs.email && inputs.password) {
		const validEmail = new RegExp(def.emailRule).test(inputs.email);
		const validPassword = new RegExp(def.passRule).test(inputs.password);

		return noEmpty && validEmail && validPassword && isLegalAge(inputs.bod);
	}
	return noEmpty && isLegalAge(inputs.bod);
}

export const isLegalAge = (bod: string[]) => {
	if (bod.every((d) => !(d.length > 1))) return false;
	const dob = new Date(`${bod[1]}/${bod[0]}/${bod[2]}`);
	const diff = new Date(Date.now() - dob.getTime());
	const age = Math.abs(diff.getUTCFullYear() - 1970);
	return age >= 18;
};
