import BaseModel from "@/api/models/BaseModel";

export default class SpeakWithUs extends BaseModel {
    private name:string;
    private message:string;
    private email:string;

    constructor(name:string, email:string, message:string) {
        super();
        this.name = name;
        this.email = email;
        this.message = message;
    }
}