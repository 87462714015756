import { defineStore } from "pinia";

export const useOtpStore = defineStore({
    id: "otpStore",
    state: () => ({
        mobileNumber: '',
        id: ''
    }),
    getters: {
    },
    actions: {
        set(id:string, mobileNumber:string) {
            this.id = id;
            this.mobileNumber = mobileNumber;
        }
    }
});