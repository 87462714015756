import { defineStore } from "pinia";
import api from "@/api";
import { from } from "linq-to-typescript";
import IdNameModel from "@/api/models/IdNameModel";
import { PromptTypes } from "@/enums/PromptTypes";
import { useGlobalStore } from "./globalStore";

export const usePropConstStore = defineStore({
	id: "propConstStore",
	state: () => ({
		areaData: [] as IdNameModel[],
		stateData: [] as IdNameModel[],
		propTypeData: [] as IdNameModel[],
		durationOfStayData: [] as IdNameModel[],
		roomTypeData: [] as IdNameModel[],
		roomBedData: [] as IdNameModel[],
		roomBathData: [] as IdNameModel[],
		roomFurnishingData: [] as IdNameModel[],
		roomFeatureData: [] as IdNameModel[],
		billIncludedData: [] as IdNameModel[],
		prefAcceptData: [] as IdNameModel[],
		placeFeatureData: [] as IdNameModel[],
		whatsNearbyData: [] as IdNameModel[],
		facilityAmenityData: [] as IdNameModel[],
		promptTitleData: [] as IdNameModel[]
	}),
	getters: {
		areas: (state) => state.areaData,
		states: (state) => state.stateData,
		propTypes: (state) => state.propTypeData,
		durationOfStay: (state) => state.durationOfStayData,
		roomType: (state) => state.roomTypeData,
		roomBed: (state) => state.roomBedData,
		roomBath: (state) => state.roomBathData,
		roomFurnishing: (state) => state.roomFurnishingData,
		roomFeature: (state) => state.roomFeatureData,
		billIncluded: (state) => state.billIncludedData,
		prefAccept: (state) => state.prefAcceptData,
		placeFeatures: (state) => state.placeFeatureData,
		whatsNearby: (state) => state.whatsNearbyData,
		facilityAmenity: (state) => state.facilityAmenityData,
		promptTitle: (state) => state.promptTitleData
	},
	actions: {
		getId(enums: IdNameModel[], name: string) {
			return from(enums).first((x) => x.name === name).id;
		},
		loadArea() {
			return new Promise<void>((resolve) => {
				if (this.areaData.length === 0 || this.stateData.length === 0)
					api.getArea().then((res) => {
						this.areaData = res.data.data.map((r: any) => ({
							name: r.Name,
							id: r.id,
							State: r.State.Name
						})) as IdNameModel[];

						this.stateData = from(res.data.data)
							.select((x: any) => x.State)
							.select((x: any) => new IdNameModel(x.id, x.Name))
							.groupBy((x: IdNameModel) => x.id)
							.select((x) => x.firstOrDefault())
							.toArray() as IdNameModel[];

						resolve();
					});
				else resolve();
			});
		},
		loadType() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.propTypeData.length === 0)
					api.getPropertyType().then((res) => {
						this.propTypeData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id,
									icon: r.Icon
								} as IdNameModel)
						);
						resolve(this.propTypes);
					});
				else resolve(this.propTypes);
			});
		},
		loadDurationOfStay() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.durationOfStayData.length === 0)
					api.getDurationOfStay().then((res) => {
						this.durationOfStayData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id,
									label: r.DisplayName,
									value: r.DisplayName
								} as IdNameModel)
						);
						resolve(this.durationOfStay);
					});
				else resolve(this.durationOfStay);
			});
		},
		loadRoomType() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.roomTypeData.length === 0)
					api.getRoomType().then((res) => {
						this.roomTypeData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id,
									label: r.DisplayName,
									value: r.DisplayName
								} as IdNameModel)
						);
						resolve(this.roomType);
					});
				else resolve(this.roomType);
			});
		},
		loadRoomBed() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.roomBedData.length === 0)
					api.getRoomBed().then((res) => {
						this.roomBedData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id,
									label: r.DisplayName,
									value: r.DisplayName
								} as IdNameModel)
						);
						resolve(this.roomBed);
					});
				else resolve(this.roomBed);
			});
		},
		loadRoomBath() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.roomBathData.length === 0)
					api.getRoomBath().then((res) => {
						this.roomBathData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id,
									label: r.DisplayName,
									value: r.DisplayName
								} as IdNameModel)
						);
						resolve(this.roomBath);
					});
				else resolve(this.roomBath);
			});
		},
		loadRoomFurnishing() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.roomFurnishingData.length === 0)
					api.getRoomFurnishing().then((res) => {
						this.roomFurnishingData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id,
									label: r.DisplayName,
									value: r.DisplayName
								} as IdNameModel)
						);
						resolve(this.roomFurnishing);
					});
				else resolve(this.roomFurnishing);
			});
		},
		loadRoomFeatures() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.roomFeatureData.length === 0)
					api.getRoomFeature().then((res) => {
						this.roomFeatureData = res.data.data.map((r: any) => ({
							name: r.DisplayName,
							id: r.id,
							label: r.DisplayName,
							value: r.DisplayName,
							icon: r.Icon
						}));
						resolve(this.roomFeature);
					});
				else resolve(this.roomFeature);
			});
		},
		loadBillIncluded() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.billIncludedData.length === 0)
					api.getBillIncluded().then((res) => {
						this.billIncludedData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id
								} as IdNameModel)
						);
						resolve(this.billIncluded);
					});
				else resolve(this.billIncluded);
			});
		},
		loadPrefAccept() {
			return new Promise<any[]>((resolve) => {
				if (this.prefAcceptData.length === 0)
					api.getPrefAccept().then((res) => {
						this.prefAcceptData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id,
									type: r.Type
								} as any)
						);
						resolve(this.prefAccept);
					});
				else resolve(this.prefAccept);
			});
		},
		loadPlaceFeature() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.placeFeatureData.length === 0)
					api.getPlaceFeature().then((res) => {
						this.placeFeatureData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id
								} as IdNameModel)
						);
						resolve(this.placeFeatures);
					});
				else resolve(this.placeFeatures);
			});
		},
		loadWhatsNearby() {
			return new Promise<any[]>((resolve) => {
				if (this.whatsNearbyData.length === 0)
					api.getWhatsNearby().then((res) => {
						this.whatsNearbyData = res.data.data.map(
							(r: any) =>
								({
									name: r.Title,
									id: r.id,
									icon: r.Icon
								} as any)
						);
						resolve(this.whatsNearby);
					});
				else resolve(this.whatsNearby);
			});
		},
		loadFacilityAmenity() {
			return new Promise<any[]>((resolve) => {
				if (this.facilityAmenityData.length === 0)
					api.getFacilityAmenity().then((res) => {
						this.facilityAmenityData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id,
									type: r.Type
								} as IdNameModel)
						);
						resolve(this.facilityAmenity);
					});
				else resolve(this.facilityAmenity);
			});
		},
		loadPromptTitle() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.promptTitleData.length === 0)
					api.getPromptTitle().then((res) => {
						this.promptTitleData = res.data.data
							.filter((x: any) => x.Type === PromptTypes.Property)
							.map(
								(r: any) =>
									({
										name: r.DisplayName,
										id: r.id,
										show: r.Show,
										placeholder: r.Placeholder
									} as IdNameModel)
							);
						resolve(this.promptTitle);
					});
				else resolve(this.promptTitle);
			});
		},
		async loadAll() {
			const loads = [
				this.loadArea,
				this.loadType,
				this.loadDurationOfStay,
				this.loadRoomType,
				this.loadRoomBed,
				this.loadRoomBath,
				this.loadRoomFurnishing,
				this.loadRoomFeatures,
				this.loadBillIncluded,
				this.loadPrefAccept,
				this.loadPlaceFeature,
				this.loadFacilityAmenity,
				this.loadPromptTitle,
				this.loadWhatsNearby
			];
			loads.forEach(async (load) => {
				await load();
				useGlobalStore().setPercent(1);
			});
		}
	},
	persist: {
		paths: [
			"areaData",
			"stateData",
			"propTypeData",
			"durationOfStayData",
			"roomTypeData",
			"roomBedData",
			"roomBathData",
			"roomFurnishingData",
			"roomFeatureData",
			"billIncludedData",
			"prefAcceptData",
			"placeFeatureData",
			"whatsNearbyData",
			"facilityAmenityData",
			"promptTitleData"
		],
		storage: sessionStorage
	}
});
