import BaseModel from "@/api/models/BaseModel";

export class UserModel extends BaseModel {
	first_name: string;
	last_name: string;
	mobile_number: string;
	email: string;
	age: number;
	gender: string;
	avatar?: string;

	constructor(
		first_name: string,
		last_name: string,
		mobile_number: string,
		email: string,
		age: number,
		gender: string,
		avatar?: string
	) {
		super();
		this.first_name = first_name;
		this.last_name = last_name;
		this.mobile_number = mobile_number;
		this.email = email;
		this.avatar = avatar;
		this.age = age;
		this.gender = gender;
	}
}

export class UserSaveModel extends BaseModel {
	last_name: string;
	first_name: string;
	email_notifications: boolean;

	constructor(
		firstName: string,
		lastName: string,
		emailNotifications: boolean,
	) {
		super();
		this.first_name = firstName;
		this.last_name = lastName;
		this.email_notifications = emailNotifications;
	}
}
