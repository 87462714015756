export default {
	names: [
		'Internet',
		'Water bill',
		'Electricity bill',
		'Room cleaning service',
		'Regular maintenance bill',
	],
	frequencies: [
		'Once/1ce',
		'Weekly/wk',
		'Monthly/mo',
		'Quarterly/qtr',
		'Yearly/yr',
	],
};
