export default {
	place: [
		{ name: "Internet", emoji: `📡` },
		{ name: "Laundry room", emoji: `👕` },
		{ name: "TV", emoji: `📺` },
		{ name: "Kitchen", emoji: `🍳` },
		{ name: "Indoor Parking", emoji: `🚗` },
		{ name: "Garage", emoji: `🔧` }
	],
	facilities: [
		{ name: "24/7 Security guard", emoji: `👮` },
		{ name: "Playground", emoji: `🍭` },
		{ name: "Access card entry", emoji: `🚪` },
		{ name: "Restaurant", emoji: `🍽` },
		{ name: "BBQ Area", emoji: `🍖` },
		{ name: "Multi purpose hall", emoji: `🎤` },
		{ name: "Laundromat", emoji: `👔` },
		{ name: "Park", emoji: `🌳` },
		{ name: "Building parking", emoji: `` }
	],
	recreation: [
		{ name: "Swimming pool", emoji: `🏊` },
		{ name: "Gym", emoji: `🏋` },
		{ name: "Tennis court", emoji: `🎾` },
		{ name: "Badminton court", emoji: `🏸` },
		{ name: "Darts", emoji: `🎯` },
		{ name: "Futsal court", emoji: `⚽` },
		{ name: "Basketball court", emoji: `🏀` },
		{ name: "Pingpong room", emoji: `🏓` }
	]
};
