import months from "@/constants/months";
import { def } from "@/constants";
import { v4 as uuid } from "uuid";
import { usePropListingStore } from "@/stores/propertyListingStore";
import { useHousemateFormStore } from "@/stores/housemateFormStore";

export const dateToString = function (date: string): string {
	const dateO = new Date(date);
	const day = dateO.getDate();
	const monthIndex = dateO.getMonth();
	const monthName = months[monthIndex];
	const year = dateO.getFullYear();
	return `${day} ${monthName} ${year}`;
};

export const convertPicture = (photo: File | string) => {
	if (photo instanceof File) {
		return URL.createObjectURL(photo as File);
	} else return `${def.cdnUrl}${photo}.jpg`;
};

export const toFile = async (fileBase: string) => {
	const file = await dataUrlToFile(fileBase, "photo");
	return file;
};
// serialize to base64
export const toBase64 = (file: File) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});
export async function dataUrlToFile(
	dataUrl: string,
	fileName: string
): Promise<File> {
	const res: Response = await fetch(dataUrl);
	const blob: Blob = await res.blob();
	return new File([blob], fileName, { type: "image/jpeg" });
}
export const convertIcon = (id: string) => `${def.cdnUrl}${id}.svg`;
export const convertDate = (date: string) => {
	const t = new Date(date);
	return t.getDate() + " " + months[t.getMonth()] + " " + t.getFullYear();
};

export const initialName = (name: string) => {
	const firstInitial = name.split(" ")[0].charAt(0) ?? "";
	const lastInitial = name.split(" ")[1] ? name.split(" ")[1].charAt(0) : "";
	return (firstInitial + lastInitial).toUpperCase();
};
export const joinName = (firstName: string, lastName: string) => {
	const first = firstName ? firstName : "";
	const last = lastName ? lastName : "";
	return first + " " + last;
};

export const clearLocalStorage = () => {
	localStorage.removeItem("propertyListingStore");
	localStorage.removeItem("housemateFormStore");
	usePropListingStore().clear();
	useHousemateFormStore().clear();
};

export const dateDiff = (later: number, recent: number) => {
	const diff = Math.trunc(later - recent);

	switch (true) {
		case diff < 1000 * 60:
			return [
				Math.trunc(diff / 1000),
				diff === 1000 ? "second" : "seconds"
			];
		case diff >= 1000 * 60 && diff < 1000 * 60 * 60:
			return [
				Math.trunc(diff / (1000 * 60)),
				diff === 1000 * 60 ? "minute" : "minutes"
			];
		case diff >= 1000 * 60 * 60 && diff < 1000 * 60 * 60 * 24:
			return [
				Math.trunc(diff / (1000 * 60 * 60)),
				diff === 1000 * 60 * 60 ? "hour" : "hours"
			];
		default:
			return [
				Math.trunc(diff / (1000 * 60 * 60 * 24)),
				diff === 1000 * 60 * 60 * 24 ? "day" : "days"
			];
	}
};

export const randomString = (length: number) => {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(
			Math.floor(Math.random() * charactersLength)
		);
		counter += 1;
	}
	return result;
};

export const randomFile = (oldFile: File) => {
	return new File([oldFile], `${uuid()}.jpg`, { type: "image/jpeg" });
};

export const omitInitial = (number: string) => {
	return number.startsWith("+6") ? number.slice(2) : number;
};

// ^ is bold
export const textFormatFlag = /\^/g;

export const isProduction = process.env.NODE_ENV === "production";

export const formatISODate = (date: string | number | Date) => {
	if (typeof date === "number")
		return new Date(Date.now() - date).toISOString().slice(0, 10);
	return new Date(date).toISOString().slice(0, 10);
};
