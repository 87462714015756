import BaseModel from "@/api/models/BaseModel";

export default class ContactUs extends BaseModel {
    private name:string;
    private mobile_number:string;
    private company:string;
    private email:string;

    constructor(name:string, email:string, mobile_number:string, company:string) {
        super();
        this.name = name;
        this.email = email;
        this.mobile_number = mobile_number;
        this.company = company;
    }
}