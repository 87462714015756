import { defineStore } from "pinia";
import IdNameModel from "@/api/models/IdNameModel";
import api from "@/api";
import Directories from "@/api/models/Directories";

export const useFooterStore = defineStore({
	id: "footerStore",
	state: () => ({
		logo: {} as File | string,
		socialsData: [] as any[],
		description: {} as IdNameModel,
		copyrightData: "",
		directoriesData: [] as Directories[]
	}),
	getters: {
		copyright: (state) => state.copyrightData,
		directories: (state) => state.directoriesData,
		socials: (state) => state.socialsData
	},
	actions: {
		loadCopyright() {
			return new Promise<string>((resolve) => {
				if (this.copyright.length === 0)
					api.getFooterCopyright().then((res) => {
						this.copyrightData = res.data.data[0].copyright;
						resolve(this.copyright);
					});
				else resolve(this.copyright);
			});
		},
		loadDirectories() {
			return new Promise<Directories[]>((resolve) => {
				if (this.directoriesData.length === 0)
					api.getFooterDirectories().then((res) => {
						this.directoriesData = res.data.data.map((r: any) => ({
							title: r.Title,
							contents: r.Name.map((ql: any) => ({
								label: ql.Links,
								to: ql.Path
							}))
						})) as Directories[];
						resolve(this.directories);
					});
				else resolve(this.directories);
			});
		},
		loadSocials() {
			return new Promise<{ platform: string; link: string }[]>(
				(resolve) => {
					if (this.socials.length === 0)
						api.getFooterSocials().then((res) => {
							this.socialsData = res.data.data.map((r: any) => ({
								platform: r.Platform,
								link: r.Link
							}));
							resolve(this.socials);
						});
					else resolve(this.socials);
				}
			);
		}
	}
});
