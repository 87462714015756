import {
	AuthView,
	SignupView,
	OTPView,
	VerifyOTPView,
	EmailVerificationView,
	ForgotPasswordView
} from "../views";
import EmailVerify from "@/components/custom/EmailVerify";
import Logout from "@/components/custom/Logout";
import RequestReset from "@/components/Auth/Forgot/RequestReset.vue";
import ResetPassword from "@/components/Auth/Forgot/ResetPassword.vue";

export default [
	{
		path: "/login",
		name: "login",
		meta: { requiresAuth: false },
		component: AuthView
	},
	{
		path: "/signup",
		name: "signup",
		meta: { requiresAuth: false },
		component: SignupView
	},
	{
		path: "/signup/otp",
		name: "signup-otp",
		meta: { requiresAuth: true },
		component: OTPView
	},
	{
		path: "/signup/verify",
		name: "signup-verify",
		meta: { requiresAuth: true },
		component: VerifyOTPView
	},
	{
		path: "/signup/email",
		name: "signup-email",
		meta: { requiresAuth: false },
		component: EmailVerificationView
	},
	{
		path: "/logout",
		name: "logout",
		meta: { requiresAuth: false },
		component: Logout
	},
	{
		path: "/forgot",
		name: "forgot-password",
		meta: { requiresAuth: false },
		component: ForgotPasswordView,
		children: [
			{
				path: "request",
				name: "forgot-password-request",
				meta: { requiresAuth: false },
				component: RequestReset
			},
			{
				path: "email",
				name: "forgot-password-email",
				meta: { requiresAuth: false },
				component: EmailVerificationView
			},
			{
				path: "reset",
				name: "forgot-password-reset",
				meta: { requiresAuth: false },
				component: ResetPassword
			}
		]
	},
	{
		path: "/email/verify/:id",
		name: "email-verify",
		meta: { requiresAuth: true },
		component: EmailVerify
	}
];
