export const navbar = {
	info: "Info",
	support: "Support"
};
export const topBanner = {
	header: "Friendly, smart, and easy to use roomshare platform",
	body: "Have a spare room? \nLooking for housemate? ",
	lists: [
		"Create property ad with no charges",
		"Make appointments from there",
		"Share it anywhere anytime"
	]
};
export const card = {
	title: "One month free trial",
	desc: "Whether it’s an apartment, landed home or studio.",
	note: "Look out for people that are looking for you."
};
export const details = [
	{
		title: "Personal tips and guidance",
		desc: "Join our events and learn to advertise your incredible place with us."
	},
	{
		title: "Easy and fast listing process",
		desc: "Incrediblly easy and fast sign up process. Create an account under 3 minutes."
	},
	{
		title: "Get answers on to any question",
		desc: "Find people looking to share a place with you on Howsmate."
	}
];
export const register = {
	title: "Register your agencies",
	subtitle: "Be a Super Early Bird, we have something special for you.",
	desc: "We are giving away 3 forever free listings when you pre-register with your REN number and be invited to our exclusive event. We are launching soon!",
	enlist: "Register Now"
};
