import { FavoriteListsView } from "@/views";

export default [
	{
		path: "/favorite",
		name: "favorite",
		meta: { requiresAuth: true },
		component: FavoriteListsView
	}
];
