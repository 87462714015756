import { defineStore } from "pinia";
export const useGlobalStore = defineStore({
	id: "globalStore",
	state: () => ({
		loading: false,
		progressive: false,
		total: 0,
		percent: 0
	}),
	getters: {
		isLoading: (state) => state.loading
	},
	actions: {
		setLoading() {
			this.loading = !this.loading;
		},
		setProgressive() {
			this.progressive = !this.progressive;
		},
		setTotalPercent(total: number) {
			this.total = total;
		},
		setPercent(percent: number) {
			this.percent += percent;
		},
		reset() {
			this.percent = 0;
		}
	}
});
