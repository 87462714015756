
import { defineComponent } from 'vue';
import api from '@/api';
import ContactUs from '@/api/models/ContactUs';
import { recaptchaConst } from '@/constants/recaptcha';

export default defineComponent({
	name: 'SupportView',
	components: { },
	data() {
		return {
			siteKey: recaptchaConst.siteKey,
			cards: [
				{
					title: 'Account upgrades',
					subtitle:
						'Get 2 extra free listing on top of the basic account when you start with us!',
				},
				{
					title: 'Exclusive events',
					subtitle:
						'Be invited to events for free, meet the people behind Howsmate and the industry.',
				},
				{
					title: 'Be first be new',
					subtitle:
						'Differentiate yourself to your audience when you advertise with us and share it on channel.',
				},
			],
			textFields: [
				{
					title: 'name',
					placeholder: 'Name',
				},
				{
					title: 'email',
					placeholder: 'Email',
				},
				{
					title: 'mobile',
					placeholder: 'Mobile',
				},
				{
					title: 'companyName',
					placeholder: 'Company Name',
				},
			],
		};
	},
	methods: {
		submitContactUs() {
			// eslint-disable-next-line
			const form = this.$refs.contactForm as any;
			const contactForm = new ContactUs(
				form.name.value,
				form.email.value,
				form.mobile.value,
				form.companyName.value,
			);
			api.postContactUs(contactForm);
		},
	},
});
