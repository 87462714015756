import { RouteRecordRaw } from "vue-router";
import { ChatListView, ChatPanelView } from "../views";
import ChatBackdrop from "@/components/Chat/common/ChatBackdrop.vue";
import RequestView from "../views/RequestView.vue";

export default [
	{
		path: "/chat",
		name: "chat",
		meta: { requiresAuth: true },
		component: ChatListView,
		children: [
			{
				path: "",
				name: "chat-lists",
				meta: { requiresAuth: true },
				component: ChatBackdrop
			},
			{
				path: ":id",
				name: "chat-panel",
				meta: { requiresAuth: true },
				component: ChatPanelView
			},
			{
				path: "request",
				name: "request-lists",
				meta: { requiresAuth: true },
				component: ChatBackdrop
			},
			{
				path: "request/:id",
				name: "request-detail",
				meta: { requiresAuth: true },
				component: RequestView
			}
		] as Array<RouteRecordRaw>
	}
];
