import { ArticleView } from "@/views";
import ArticleDetail from "@/components/Article/ArticleDetail.vue";
import ArticleLists from "@/components/Article//ArticleLists.vue";

export default [
	{
		path: "/article",
		name: "article",
		meta: { requiresAuth: false },
		component: ArticleView,
		children: [
			{
				path: "",
				name: "article-lists",
				meta: { requiresAuth: false },
				component: ArticleLists
			},
			{
				path: ":id",
				name: "article-detail",
				meta: { requiresAuth: false },
				component: ArticleDetail
			}
		]
	}
];
