import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/index.css";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createHead } from "@vueuse/head";
import VueDragscroll from "vue-dragscroll";

const app = createApp(App);
const pinia = createPinia();
const head = createHead();

pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(router);
app.use(head);
app.use(VueDragscroll);

app.mount("#app");
