import axios from "axios";
import type ContactUs from "./models/ContactUs";
import type Login from "./models/Login";
import type SpeakWithUs from "./models/SpeakWithUs";
import type Signup from "./models/Signup";
import { useAuthStore } from "@/stores/authStore";
import type { LocationQuery } from "vue-router";
import type { ChatModel } from "@/models/chat/ChatModel";
import type { Report } from "@/models/report/ReportModel";
import { def } from "@/constants";
import { randomFile } from "@/helper/Utilities";
import { AppointmentModel } from "./interface/AppointmentModel";
import { PatchBody } from "./models/PatchBody";
import { useGlobalStore } from "@/stores/globalStore";

export const baseUrl =
	process.env.NODE_ENV === "production"
		? "https://api.howsmate.com.my"
		: "https://dev-api.howsmate.com.my";

export const apiConstants = {
	public: {
		appointment: {
			base: `${baseUrl}/items/Appointment?fields[]=*,PropertyListingID.*.*&sort=-date_created`,
			byId: `${baseUrl}/items/Appointment/:id?fields[]=*,PropertyListingID.*.*`
		},
		article: {
			lists: `${baseUrl}/items/Article?fields[]=*&sort[]=Order`,
			byId: `${baseUrl}/items/Article/:id?fields[]=*`
		},
		authentication: {
			login: `${baseUrl}/auth/login`,
			resendEmail: `${baseUrl}/flows/trigger/2771ab95-0132-4250-b5ea-35407d844545?uid=:uid`,
			requestReset: `${baseUrl}/auth/password/request`,
			passwordReset: `${baseUrl}/auth/password/reset`
		},
		chat: {
			base: `${baseUrl}/items/Chat`,
			count: `${baseUrl}/items/Chat?filter[Seen][_eq]=false&groupBy[]=Receiver&aggregate[count]=*&filter[_or][0][Receiver][_eq]=:participant2`,
			chats: `${baseUrl}/items/Chat?filter[_or][0][Receiver][_eq]=:participant&filter[_or][1][user_created][_eq]=:participant&sort[]=date_created&fields=*.*`,
			conversation: `${baseUrl}/items/Conversation?fields=*.*`,
			latest: `${baseUrl}/items/Conversation`
		},
		listing: {
			property: `${baseUrl}/items/Property?fields[]=*.*,Photos.PropertyPhoto_id.*,PlaceFeatures.PlaceFeature_id.*,Accepting.PreferenceAccept_id.DisplayName,Room.Room_id.*.DisplayName&sort=-date_created&filter[Active][_eq]=true`,
			propertyWithFilter: `${baseUrl}/items/Property?fields[]=*.*,Photos.PropertyPhoto_id.*,PlaceFeatures.PlaceFeature_id.*,AvailableDate,AdvertisedArea.State.Name,AdvertisedArea.Name,Accepting.PreferenceAccept_id.DisplayName,Room.Room_id.*.DisplayName&sort=-date_created&filter=`,
			propertyById: `${baseUrl}/items/Property/:id?fields[]=*.*,Photos.PropertyPhoto_id.*,PlaceFeatures.PlaceFeature_id.DisplayName,Room.Room_id.*,AdvertisedArea.State.Name,Room.Room_id.*.DisplayName,Room.Room_id.Feature.RoomFeature_id.DisplayName,Accepting.PreferenceAccept_id.DisplayName,Prompts.Prompt_id.*,OtherBill.Bill_id.*,BillIncluded.BillIncluded_id.*,FacilitiesAmenities.FacilityAmenity_id.*,Prompts.Prompt_id.Title.DisplayName,Landmark.Landmark_id.*`,
			userPropertyCount: `${baseUrl}/items/Property?filter[User][_contains]=$CURRENT_USER&filter[Active][_eq]=true&aggregate[count]=*`,
			housemate: `${baseUrl}/items/Housemate?fields[]=*.*,Preferences.HmPreference_id.*,SelectedAreas.Area_id.*.*&sort=-date_created`,
			housemateWithFilter: `${baseUrl}/items/Housemate?fields[]=*.*,Preferences.HmPreference_id.*,SelectedAreas.Area_id.*.*&sort=-date_created&filter=`,
			housemateById: `${baseUrl}/items/Housemate/:id?fields[]=*.*,SelectedAreas.Area_id.*,Preferences.HmPreference_id.*.*,Prompts.Prompt_id.*.*`
		},
		forms: {
			contact_us: `${baseUrl}/items/ContactUs`,
			speak_with_us: `${baseUrl}/items/SpeakWithUs`,
			signup: `${baseUrl}/users`,
			report: `${baseUrl}/items/Report`,
			area: `${baseUrl}/items/Area?fields[]=*.*`,
			propertyType: `${baseUrl}/items/PropertyType`,
			durationOfStay: `${baseUrl}/items/DurationOfStay`,
			roomType: `${baseUrl}/items/RoomType`,
			roomBed: `${baseUrl}/items/RoomBed`,
			roomBath: `${baseUrl}/items/RoomBath`,
			roomFurnishing: `${baseUrl}/items/RoomFurnishing`,
			roomFeature: `${baseUrl}/items/RoomFeature`,
			billIncluded: `${baseUrl}/items/BillIncluded`,
			prefAccept: `${baseUrl}/items/PreferenceAccept`,
			placeFeature: `${baseUrl}/items/PlaceFeature`,
			whatsNearby: `${baseUrl}/items/Nearby`,
			facilityAmenity: `${baseUrl}/items/FacilityAmenity`,
			promptTitle: `${baseUrl}/items/PromptTitle`,
			propertyForm: `${baseUrl}/items/Property`,
			lookingForPlace: `${baseUrl}/items/LookingForPlace`,
			hmPreference: `${baseUrl}/items/HmPreference?fields[]=*.*`,
			hmEmploymentStatus: `${baseUrl}/items/HmEmploymentStatus`,
			housemateForm: `${baseUrl}/items/Housemate`
		},
		favorite: {
			baseProperty: `${baseUrl}/items/Favorite_Property`,
			baseHousemate: `${baseUrl}/items/Favorite_Housemate`,
			property: `${baseUrl}/items/Favorite_Property?fields=*,Property_Listings.*.*,Property_Listings.Photos.PropertyPhoto_id.*,Property_Listings.PlaceFeatures.PlaceFeature_id.*,Property_Listings.Accepting.PreferenceAccept_id.DisplayName,Property_Listings.Room.Room_id.*.*.DisplayName%26sort=-date_created`,
			housemate: `${baseUrl}/items/Favorite_Housemate?fields=*,Housemate_Listings.*.*,Housemate_Listings.Preferences.HmPreference_id.*,Housemate_Listings.SelectedAreas.Area_id.*.*`
		},
		validation: {
			recaptcha: `${baseUrl}/flows/trigger/fc4e022e-60c1-451b-93f7-d0466e534bf8`,
			checkEmail: `${baseUrl}/flows/trigger/ca37f1ed-395b-4296-8cc7-777f52257905`,
			tokenVerify: `${baseUrl}/flows/trigger/4344a8cc-bd61-44d6-bd46-7d85c07aaf72`,
			sendOtp: `${baseUrl}/flows/trigger/14d4dbfb-a9ca-4b78-a261-f5bf44208085`
		},
		footer: {
			copyright: `${baseUrl}/items/Copyright?fields[]=copyright`,
			directories: `${baseUrl}/items/Directories?fields[]=*.Links,*.Path`,
			socials: `${baseUrl}/items/Social?fields[]=*`
		},
		faq: `${baseUrl}/items/FAQ`,
		assets: `${def.cdnUrl}`
	},
	private: {
		refreshToken: `${baseUrl}/auth/refresh`,
		logout: `${baseUrl}/auth/logout`,
		users: `${baseUrl}/users`,
		usersById: `${baseUrl}/users/:id`,
		me: `${baseUrl}/users/me`,
		stripe: {
			config: `${baseUrl}/stripe-payment/config`,
			subscription: `${baseUrl}/stripe-payment/create-subscription`,
			customer: `${baseUrl}/stripe-payment/create-customer`,
			subscriptions: `${baseUrl}/stripe-payment/subscriptions`,
			cancel: `${baseUrl}/stripe-payment/cancel-subscription`
		}
	}
};

function getHeaders(moreHeader: any = null) {
	const authStore = useAuthStore();
	return {
		headers: {
			Authorization: "Bearer " + authStore.$state.access_token,
			...moreHeader
		}
	};
}

export default {
	getBaseUrl() {
		return baseUrl;
	},
	getGoogleLoginUrl() {
		return `${this.getBaseUrl()}/auth/login/google?redirect=${
			location.protocol
		}//${location.host}/login?auth=google`;
	},
	getFacebookLoginUrl() {
		return `${this.getBaseUrl()}/auth/login/facebook?redirect=${
			location.protocol
		}//${location.host}/login?auth=facebook`;
	},
	getArticles() {
		return axios.get(apiConstants.public.article.lists);
	},
	getArticleById(id: string) {
		const url = apiConstants.public.article.byId.replace(":id", id);
		return axios.get(url);
	},
	postContactUs(form: ContactUs) {
		return axios.post(apiConstants.public.forms.contact_us, form);
	},
	postSpeakWithUs(form: SpeakWithUs) {
		return axios.post(apiConstants.public.forms.speak_with_us, form);
	},
	postLogin(form: Login) {
		return axios
			.post(apiConstants.public.authentication.login, form, {
				withCredentials: true
			})
			.then(function (res) {
				const authStore = useAuthStore();
				authStore.setTokens(
					res.data.data.access_token,
					res.data.data.refresh_token,
					res.data.data.expires
				);
			});
	},
	postReport(form: Report) {
		return axios.post(apiConstants.public.forms.report, form, getHeaders());
	},
	getUserById(id: string) {
		return axios.get(apiConstants.private.users + `/${id}`, getHeaders());
	},
	getCurrentUser() {
		return axios.get(apiConstants.private.me, getHeaders());
	},
	getUsersInfo() {
		return axios.get(apiConstants.private.users, getHeaders());
	},
	async postAvatar(file: File) {
		const formData = new FormData();
		formData.append("folder", "25d59326-524e-410d-87e0-b86f8640dcc7");
		formData.append("file", randomFile(file));
		let data;

		return await axios
			.post(`${baseUrl}/files`, formData, getHeaders())
			.then((res) => {
				data = res.data.data.id;
				return data;
			})
			.catch((error: any) => {
				throw error;
			});
	},
	getArea() {
		return axios.get(apiConstants.public.forms.area);
	},
	getPropertyType() {
		return axios.get(apiConstants.public.forms.propertyType);
	},
	getDurationOfStay() {
		return axios.get(apiConstants.public.forms.durationOfStay);
	},
	getRoomType() {
		return axios.get(apiConstants.public.forms.roomType);
	},
	getRoomBed() {
		return axios.get(apiConstants.public.forms.roomBed);
	},
	getRoomBath() {
		return axios.get(apiConstants.public.forms.roomBath);
	},
	getRoomFurnishing() {
		return axios.get(apiConstants.public.forms.roomFurnishing);
	},
	getRoomFeature() {
		return axios.get(apiConstants.public.forms.roomFeature);
	},
	getBillIncluded() {
		return axios.get(apiConstants.public.forms.billIncluded);
	},
	getPrefAccept() {
		return axios.get(apiConstants.public.forms.prefAccept);
	},
	getPlaceFeature() {
		return axios.get(apiConstants.public.forms.placeFeature);
	},
	getWhatsNearby() {
		return axios.get(apiConstants.public.forms.whatsNearby);
	},
	getFacilityAmenity() {
		return axios.get(apiConstants.public.forms.facilityAmenity);
	},
	getPromptTitle() {
		return axios.get(
			apiConstants.public.forms.promptTitle +
				"?filter[status][_eq]=published"
		);
	},
	getHmLookingForPlace() {
		return axios.get(
			apiConstants.public.forms.lookingForPlace,
			getHeaders()
		);
	},
	getHmPreferences() {
		return axios.get(apiConstants.public.forms.hmPreference, getHeaders());
	},
	getHmEmploymentStatus() {
		return axios.get(
			apiConstants.public.forms.hmEmploymentStatus,
			getHeaders()
		);
	},
	getPropertyListing(paginate?: { page?: string; limit?: string }) {
		if (!paginate) return axios.get(apiConstants.public.listing.property);
		else {
			return axios.get(
				apiConstants.public.listing.property +
					`${paginate.page ? "&page=" + paginate.page : ""}${
						paginate.limit ? "&limit=" + paginate.limit : ""
					}`
			);
		}
	},
	getPropertyCount() {
		return axios.get(
			apiConstants.public.listing.property + "&aggregate[count]=*"
		);
	},
	getHousemateCount() {
		return axios.get(
			apiConstants.public.listing.housemate + "&aggregate[count]=*"
		);
	},
	getUserPropertyListing(paginate?: { page: string; limit: string }) {
		if (paginate)
			return axios.get(
				apiConstants.public.listing.property +
					"&filter[_and][0][User][_contains]=$CURRENT_USER" +
					`&limit=${paginate.limit}&page=${paginate.page}`,
				getHeaders()
			);
		else
			return axios.get(
				apiConstants.public.listing.property +
					"&filter[_and][0][User][_contains]=$CURRENT_USER",
				getHeaders()
			);
	},
	getUserPropertyListingCount() {
		return axios.get(
			apiConstants.public.listing.userPropertyCount,
			getHeaders()
		);
	},
	getPropertyListingById(id: number) {
		const url = apiConstants.public.listing.propertyById.replace(
			":id",
			id.toString()
		);
		return axios.get(url);
	},
	getHousemateListing(paginate?: { page: string; limit: string }) {
		if (paginate)
			return axios.get(
				apiConstants.public.listing.housemate +
					`&limit=${paginate.limit}&page=${paginate.page}` +
					"&filter[_and][0][User][_contains]=$CURRENT_USER",
				getHeaders()
			);
		else return axios.get(apiConstants.public.listing.housemate);
	},
	getUserHousemateListing(paginate?: { page: string; limit: string }) {
		if (paginate)
			return axios.get(
				apiConstants.public.listing.housemate +
					`&limit=${paginate.limit}&page=${paginate.page}`
			);
		else
			return axios.get(
				apiConstants.public.listing.housemate +
					"&filter[_and][0][user_created][_contains]=$CURRENT_USER",
				getHeaders()
			);
	},
	getHousemateListingById(id: number | string) {
		const url = apiConstants.public.listing.housemateById.replace(
			":id",
			id.toString()
		);
		return axios.get(url);
	},
	getHousemateListingWithFilter(
		query: LocationQuery,
		paginate?: { page: string; limit: string }
	) {
		const state = query.atState ?? "";
		const area = query.atArea ?? "";
		const date = query.moveDate;
		let filter;
		if (date)
			filter = {
				_and: [
					{ SelectedAreas: { Area_id: { Name: { _eq: area } } } },
					{
						SelectedAreas: {
							Area_id: { State: { Name: { _eq: state } } }
						}
					},
					{ MovingDate: { _lte: date } }
				]
			};
		else
			filter = {
				_and: [
					{ SelectedAreas: { Area_id: { Name: { _eq: area } } } },
					{
						SelectedAreas: {
							Area_id: { State: { Name: { _eq: state } } }
						}
					}
				]
			};
		if (paginate)
			return axios.get(
				apiConstants.public.listing.housemateWithFilter +
					JSON.stringify(filter) +
					`&limit=${paginate.limit}&page=${paginate.page}`
			);
		else
			return axios.get(
				apiConstants.public.listing.housemateWithFilter +
					JSON.stringify(filter)
			);
	},
	getPropertyListingWithFilter(
		query: LocationQuery,
		paginate?: { page: string; limit: string }
	) {
		const state = query.atState ?? "";
		const area = query.atArea ?? "";
		const date = query.moveDate;
		let filter;
		if (date)
			filter = {
				_and: [
					{ AdvertisedArea: { State: { Name: { _eq: state } } } },
					{ AdvertisedArea: { Name: { _eq: area } } },
					{ AvailableDate: { _lte: date } }
				]
			};
		else
			filter = {
				_and: [
					{ AdvertisedArea: { State: { Name: { _eq: state } } } },
					{ AdvertisedArea: { Name: { _eq: area } } }
				]
			};
		if (paginate)
			return axios.get(
				apiConstants.public.listing.propertyWithFilter +
					JSON.stringify(filter) +
					`&limit=${paginate.limit}&page=${paginate.page}`
			);
		else
			return axios.get(
				apiConstants.public.listing.propertyWithFilter +
					JSON.stringify(filter)
			);
	},
	patchPropertyById(id: number, jsonBody: any) {
		const url = apiConstants.public.forms.propertyForm + `/${id}`;
		return axios.patch(url, jsonBody, getHeaders());
	},
	patchHousemateById(id: number, jsonBody: any) {
		const url = apiConstants.public.forms.housemateForm + `/${id}`;
		return axios.patch(url, jsonBody, getHeaders());
	},
	patchProperties(body: PatchBody) {
		return axios.patch(
			apiConstants.public.forms.propertyForm,
			body,
			getHeaders()
		);
	},
	deletePropertyById(id: number) {
		const url = apiConstants.public.forms.propertyForm + `/${id}`;
		return axios.delete(url, getHeaders());
	},
	deleteHousemateById(id: number) {
		const url = apiConstants.public.forms.housemateForm + `/${id}`;
		return axios.delete(url, getHeaders());
	},
	getFavoriteBaseProperty() {
		return axios.get(apiConstants.public.favorite.baseProperty);
	},
	getFavoriteBaseHousemates() {
		return axios.get(apiConstants.public.favorite.baseHousemate);
	},
	getFavoriteProperty() {
		return axios.get(apiConstants.public.favorite.property, getHeaders());
	},
	getFavoriteHousemates() {
		return axios.get(apiConstants.public.favorite.housemate, getHeaders());
	},
	async addFavoriteProperty(propertyId: number) {
		const data = (await this.getFavoriteBaseProperty()).data.data as any[];

		if (data.length > 0) {
			const id = data[0].id;
			const existingFavs = data[0].Property_Listings;

			return axios.patch(
				apiConstants.public.favorite.baseProperty + `/${id}`,
				{
					Property_Listings: [propertyId, ...existingFavs]
				},
				getHeaders()
			);
		} else {
			const { user } = useAuthStore();
			return axios.post(
				apiConstants.public.favorite.baseProperty,
				{
					User: user.id,
					Property_Listings: [propertyId]
				},
				getHeaders()
			);
		}
	},
	async addFavoriteHousemate(housemateId: number) {
		const data = (await this.getFavoriteBaseHousemates()).data
			.data as any[];

		if (data.length > 0) {
			const id = data[0].id;
			const existingFavs = data[0].Housemate_Listings;

			return axios.patch(
				apiConstants.public.favorite.baseHousemate + `/${id}`,
				{
					Housemate_Listings: [housemateId, ...existingFavs]
				},
				getHeaders()
			);
		} else {
			const { user } = useAuthStore();
			return axios.post(
				apiConstants.public.favorite.baseHousemate,
				{
					User: user.id,
					Housemate_Listings: [housemateId]
				},
				getHeaders()
			);
		}
	},
	removeFavoriteProperty(id: number, propertyIds: number[]) {
		return axios.patch(
			apiConstants.public.favorite.baseProperty + `/${id}`,
			{
				Property_Listings: propertyIds
			},
			getHeaders()
		);
	},
	removeFavoriteHousemate(id: number, housemateIds: number[]) {
		return axios.patch(
			apiConstants.public.favorite.baseHousemate + `/${id}`,
			{
				Housemate_Listings: housemateIds
			},
			getHeaders()
		);
	},
	postRefreshToken() {
		return axios
			.post(
				apiConstants.private.refreshToken,
				{},
				{ withCredentials: true }
			)
			.then((res) => {
				const authStore = useAuthStore();
				authStore.setTokens(
					res.data.data.access_token,
					res.data.data.refresh_token,
					res.data.data.expires
				);
			})
			.catch(() => {
				const authStore = useAuthStore();
				authStore.clearTokens();
			});
	},
	postLogout() {
		return axios
			.post(apiConstants.private.logout, {}, { withCredentials: true })
			.finally(() => {
				const authStore = useAuthStore();
				authStore.clearTokens();
			});
	},
	postCheckEmailExist(email: string) {
		return axios
			.post(apiConstants.public.validation.checkEmail, {
				email: email
			})
			.then((r) => {
				return r.data.id != "undefined";
			});
	},
	postSignup(signupForm: Signup) {
		return axios.post(apiConstants.public.forms.signup, signupForm);
	},
	async postPropertyPhoto(files: File[]) {
		const photoId = [] as string[];

		for (const x of files) {
			const formData = new FormData();
			formData.append("folder", "f9d464ee-e481-4b6b-af2e-727680395628");
			formData.append("file", randomFile(x));

			await axios
				.post(`${baseUrl}/files`, formData, getHeaders())
				.then(function (res) {
					photoId.push(res.data.data.id);
					useGlobalStore().setPercent(1);
				})
				.catch((e) => {
					console.error(e);
					throw "Image upload unsuccessful. Please try a different photo.";
				});
		}
		return photoId;
	},
	postPropertyForm(jsonBody: string) {
		return axios.post(
			apiConstants.public.forms.propertyForm,
			jsonBody,
			getHeaders({
				"Content-Type": "application/json"
			})
		);
	},
	async postHousematePhoto(file: File) {
		const formData = new FormData();
		formData.append("folder", "25d59326-524e-410d-87e0-b86f8640dcc7");
		formData.append("file", randomFile(file));
		const data = await axios.post(
			`${baseUrl}/files`,
			formData,
			getHeaders()
		);
		return data.data.data.id;
	},
	postHousemateForm(jsonBody: string) {
		return axios.post(
			apiConstants.public.forms.housemateForm,
			jsonBody,
			getHeaders({
				"Content-Type": "application/json"
			})
		);
	},
	getPhotoAsset(id: string) {
		return apiConstants.public.assets + id + ".jpg";
	},
	getUnreadCount(participant1: string, participant2: string) {
		const url = apiConstants.public.chat.count.replace(
			":participant1",
			participant1
		);
		return axios.get(url, getHeaders());
	},
	getChatConversation(filter?: string) {
		if (filter === "Unread")
			return axios.get(
				apiConstants.public.chat.conversation +
					"&filter[Latest][Seen][_eq]=true",
				getHeaders()
			);

		return axios.get(apiConstants.public.chat.conversation, getHeaders());
	},
	createConversation(participant: string) {
		const { user } = useAuthStore();
		const body = {
			Participant1: user.id,
			Participant2: participant
		};
		return axios.post(
			apiConstants.public.chat.conversation,
			body,
			getHeaders()
		);
	},
	getChatMessages(participant: string) {
		const url = apiConstants.public.chat.chats.replace(
			/:participant/g,
			participant
		);
		return axios.get(url, getHeaders());
	},
	postChatMessage(body: ChatModel) {
		return axios.post(apiConstants.public.chat.base, body, getHeaders());
	},
	patchSeenChat(ids: number[]) {
		const body = {
			keys: ids,
			data: { Seen: true }
		};
		return axios.patch(apiConstants.public.chat.base, body, getHeaders());
	},
	patchLatestMessage(id: number, messageId: number) {
		const body = { Latest: messageId };
		return axios.patch(
			apiConstants.public.chat.latest + "/" + id,
			body,
			getHeaders()
		);
	},
	patchCurrentUser(uid: string, body: any) {
		return axios.patch(
			apiConstants.private.usersById.replace(":id", uid),
			body,
			getHeaders()
		);
	},
	getTokenVerify(token: string, isEmail: boolean, mobileNumber?: string) {
		const type = isEmail ? "email" : "sms";
		let params = `?token=${token}&type=${type}`;
		if (mobileNumber) params += `&mobile_number=${mobileNumber}`;
		return axios.get(
			apiConstants.public.validation.tokenVerify + params,
			getHeaders()
		);
	},
	getSmsOtp(uid: string, mobileNumber: string) {
		return axios.get(
			apiConstants.public.validation.sendOtp +
				`?uid=${uid}&mobile_number=${mobileNumber}`,
			getHeaders()
		);
	},
	getFooterCopyright() {
		return axios.get(apiConstants.public.footer.copyright);
	},
	getFooterDirectories() {
		return axios.get(apiConstants.public.footer.directories);
	},
	getFooterSocials() {
		return axios.get(apiConstants.public.footer.socials);
	},
	getResendEmail(uid: string) {
		const url = apiConstants.public.authentication.resendEmail.replace(
			":uid",
			uid
		);
		return axios.get(url, getHeaders());
	},
	postPasswordResetRequest(email: string, reset_url: string) {
		const body = { email, reset_url };
		return axios.post(
			apiConstants.public.authentication.requestReset,
			body
		);
	},
	postPasswordReset(token: string, password: string) {
		const body = { token, password };
		return axios.post(
			apiConstants.public.authentication.passwordReset,
			body
		);
	},
	getStripeConfig() {
		return axios.get(apiConstants.private.stripe.config, getHeaders());
	},
	postStripeSubscription(body: any) {
		return axios.post(
			apiConstants.private.stripe.subscription,
			body,
			getHeaders()
		);
	},
	postStripeCustomer() {
		return axios.post(
			apiConstants.private.stripe.customer,
			{},
			getHeaders()
		);
	},
	getStripeSubscriptions() {
		return axios.get(
			apiConstants.private.stripe.subscriptions,
			getHeaders()
		);
	},
	postStripeCancel(subscriptionId: string) {
		const body = {
			subscriptionId
		};
		return axios.post(
			apiConstants.private.stripe.cancel,
			body,
			getHeaders()
		);
	},
	getFAQ() {
		return axios.get(apiConstants.public.faq);
	},
	getAllAppointments() {
		return axios.get(apiConstants.public.appointment.base, getHeaders());
	},
	getAppointmentById(id: number) {
		return axios.get(
			apiConstants.public.appointment.byId.replace(":id", `${id}`),
			getHeaders()
		);
	},
	postAppointment(body: Partial<AppointmentModel>) {
		const { isLoggedIn } = useAuthStore();
		if (isLoggedIn)
			return axios.post(
				apiConstants.public.appointment.base,
				body,
				getHeaders()
			);
		else return axios.post(apiConstants.public.appointment.base, body);
	},
	patchAppointment(id: number, update: Partial<AppointmentModel>) {
		return axios.patch(
			apiConstants.public.appointment.byId.replace(":id", `${id}`),
			update,
			getHeaders()
		);
	},
	deleteAppointment(id: number) {
		return axios.delete(
			apiConstants.public.appointment.byId.replace(":id", `${id}`),
			getHeaders()
		);
	}
};
