
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'InfoView',
	data() {
		return {
			benefits: [
				{
					title: 'Easy to list',
					subtitle:
						'Create a listing in just 5 minutes with 5 easy steps.',
				},
				{
					title: 'Free to list',
					subtitle:
						'Your first listing is on us. No credit card required for you to list on Howsmate.',
				},
				{
					title: 'Keep your rent',
					subtitle:
						'Howsmate is an advertising platform we do not take rental  fees.',
				},
				{
					title: 'Be seen & Share',
					subtitle:
						'Easy to be shared on your favourite social media and messaging apps.',
				},
			],
		};
	},
});
