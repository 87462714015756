import BaseModel from "@/api/models/BaseModel";

export default class Signup extends BaseModel {
    private first_name:string;
    private last_name:string;
    private email:string;
    private password:string;
    private role:string;
    private email_notifications:boolean;

    constructor(firstName:string, lastName:string, email:string, password:string, emailNotification:boolean) {
        super();
        this.first_name = firstName;
        this.last_name = lastName;
        this.email = email;
        this.password = password;
        this.email_notifications = emailNotification;
        this.role = "8c86d33b-0276-4758-b202-299edf501f09"
    }
}