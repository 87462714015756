import api from "@/api";
import { onMounted } from "vue";
import router from "@/router";
import { useAuthStore } from "@/stores/authStore";

export default {
	setup() {
		onMounted(() => {
			api.postLogout().finally(() => {
				useAuthStore().clear();
				router.push("/");
			});
		});
	}
};
