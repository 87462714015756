import { defineStore } from "pinia";
import api from "@/api";
import { formatDate } from "@vueuse/shared";
export const usePaymentStore = defineStore({
	id: "paymentStore",
	state: () => ({
		publicKey: "",
		pricing: [] as any[],
		pricingId: "",
		subscription: {
			id: "",
			name: "",
			price: "",
			card: {} as any,
			startDate: "",
			expiryDate: "",
			cycleDate: ""
		}
	}),
	getters: {
		hasPublicKey({ publicKey }) {
			return publicKey.length > 0;
		}
	},
	actions: {
		async getProductPricing() {
			const config = (await api.getStripeConfig()).data;
			this.publicKey = config.publishableKey;
			this.pricing = config.prices;
		},
		async getSecret(code?: string) {
			const body = code
				? {
						priceId: this.pricingId,
						promotionCode: code
				  }
				: {
						priceId: this.pricingId
				  };
			await api.postStripeCustomer();
			return await api.postStripeSubscription(body);
		},
		selectPricing(type: string) {
			this.pricingId = this.pricing.find((x) =>
				x.product.name.includes(type)
			).id;
		},
		async getCurrentSubscription() {
			const subs = await api.getStripeSubscriptions();
			if (!subs.data.errors) {
				const currentActive = subs.data.subscriptions.data.find(
					(sub: any) => sub.status === "active"
				);

				if (currentActive) {
					this.subscription.id = currentActive.id;
					this.subscription.name = currentActive.plan.nickname;
					this.subscription.price = (
						currentActive.plan.amount as number
					)
						.toString()
						.slice(0, 2);
					this.subscription.card =
						currentActive.default_payment_method.card;
					this.subscription.startDate = formatDate(
						new Date(currentActive.start_date * 1000),
						"DD-MM-YYYY"
					);
					this.subscription.expiryDate = formatDate(
						new Date(currentActive.current_period_end * 1000),
						"DD-MM-YYYY"
					);
					this.subscription.cycleDate = formatDate(
						new Date(currentActive.current_period_end * 1000),
						"DD-MM-YYYY"
					);
				}
			}
		}
	},
	persist: {
		paths: ["publicKey", "pricing", "pricingId", "subscription"],
		storage: sessionStorage
	}
});
