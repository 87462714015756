import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

import auth from './auth';
import chat from './chat';
import dashboard from './dashboard';
import form from './form';
import landing from './landing';
import listings from './listings';
import article from './article';
import tnc from './tnc';
import favorite from './favorite';
import payment from './payment';
import { useAuthStore } from '@/stores/authStore';
import { NotFoundView } from '@/views';

const routes: Array<RouteRecordRaw> = [
	...article,
	...auth,
	...chat,
	...dashboard,
	...favorite,
	...form,
	...landing,
	...listings,
	...payment,
	...tnc,
	// redirect 404
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		component: NotFoundView,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		document.getElementById('app')?.scrollIntoView({ behavior: 'smooth' });
	},
});

router.beforeEach(async (to) => {
	const store = useAuthStore();

	if (store.isLoggedIn && (!store.isValid || store.isEmpty))
		await store.refreshUser();

	store.syncTokens();
	if (to.meta.requiresAuth && !store.isLoggedIn) {
		let redirect = '/login';
		if (to.fullPath) redirect += `?redirect=${to.fullPath}`;
		return redirect;
	}
});

export default router;
