export default [
	'No other housemate',
	'1 other housemate',
	'2 other housemate',
	'3 other housemate',
	'4 other housemate',
	'5 other housemate',
	'6 other housemate',
	'7 other housemate',
	'8 other housemate',
];
