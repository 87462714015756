export default {
	amount: [
		'1 bathroom',
		'2 bathrooms',
		'3 bathrooms',
		'4 bathrooms',
		'5 bathrooms',
		'6 bathrooms',
		'7 bathrooms',
		'8 bathrooms',
	],
	types: ['Ensuite', 'Shared', 'Own'],
};
export const housemateBath = ['Flexible', 'Own', 'Ensuite'];
