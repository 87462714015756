export default [
	{
		title: "Overview",
		contents: `This website is operated by Howsmate Technologies. Throughout the site, the terms “we”, “us” and “our” refer to Howsmate Technologies. Howsmate Technologies offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
    By visiting our website and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers and/ or contributors of content.
    Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
    Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
    `
	},
	{
		title: "Service Terms",
		contents: `By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
    You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
    By agreeing to these Terms of Service, you are bound by Sections 211 and 233 of the Communications and Multimedia Act 1998 (“CMA”) which prohibits the provision of offensive content (which is indecent, obscene, false or menacing) with the intent to annoy, abuse, threaten or harass any person.
    You must not transmit any worms or viruses or any code of a destructive nature.
    A breach or violation of any of the Terms will result in an immediate termination of your Services.
    `
	},
	{
		title: "General Conditions",
		subtitle: ["User's Responsibilities", "Your Message"],
		contents: [
			`We reserve the right to refuse service to anyone for any reason at any time.
    You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
    You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
    The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
    `,
			`Registration Information. You may be required to provide personal information or non personal information about Yourself in order to register for and/or use certain Services. You agree that any such information shall be accurate. You are entirely responsible for maintaining the security of Your user name and password and agree not to disclose such to any third party.
      Your Content. You agree that You are solely responsible for the content (“Content”) sent or transmitted by You or displayed or uploaded by You in using the Services and for compliance with all Laws pertaining to the Content, including, but not limited to, applicable Laws requiring You to obtain the consent of a third party to use the Content and to provide appropriate notices of third party rights. You represent and warrant that You have the right to upload the Content to Howsmate and that such use does not violate or infringe on any rights of any third party. Under no circumstances will Howsmate be liable in any way for any (a) Content that is transmitted or viewed while using the Services, (b) errors or omissions in the Content, or (c) any loss or damage of any kind incurred as a result of the use of, access to, or denial of access to Content. Although Howsmate is not responsible for any Content, Howsmate may delete any Content, at any time without notice to You, if Howsmate becomes aware that it violates any provision of this Agreement, or any law. You retain copyright and any other rights You already hold in Content which You submit, post or display on or through, the Services. 
      Your Listing : You agree that You are solely responsible for the content (“Content”) sent or transmitted by You or displayed or uploaded by You in using the Services and for compliance with all Laws pertaining to the Content, including, but not limited to, Laws requiring You to obtain the consent of a third party to use the Content and to provide appropriate notices of third party rights.
      `,
			`We want people to use Howsmate Products and services to express themselves, connect with people and to share content that is important to them, but not at the expense of the safety and well-being of others or the integrity of our community. You therefore agree not to engage in the conduct described below (or to facilitate or support others in doing so):
      You may not use our Products to do or share anything:
      That violates these Terms, our Community Standards and other terms and policies that apply to your use of our Products.
      That is unlawful, misleading, discriminatory or fraudulent.
      That infringes or violates someone else's rights, including their intellectual property rights.
      You agree that you will comply to all applicable laws including without limitation, laws relating to consumer protection, competition, local fair-trading legislation, privacy, and any other applicable advertising standards and regulations.
      You agree that and ensure that you and all employees of your company will treat Howsmate employees with courtesy and respect at all times and not threaten, harass, abuse, assault, use offensive language towards, defame or repeatedly and unnecessarily contact our employees, contractors or agents or otherwise cause them distress or discomfort
      `
		]
	},
	{
		title: "Accuracy, Completeness and Timelessness of Information",
		contents: `The Service allows you to store your Shared information with the public (hereinafter the “Shared Information (s)”), which are not made public and not intended to be made public via the Service. You agree that You are a sole owner of your Private Files and no other User or third party has a legitimate copyright or property claim against such Shared Information. You agree that the Shared Information provided to advertised on Howsmate is legal to the Laws of Malaysia, is provided with permission from the owner of the Shared Information and accurate. 
    We collect and store the Shared information You use within the Service, including files uploaded, imported from external services like Google Drive or Dropbox, downloaded, accessed, sent and shared by You. We may associate all or part of any Shared Information You use within the Service with any part or all of other files used within the Service by You or other Users for the purpose of eliminating unnecessary duplicate storage.
    We access your uploaded file information, such as but not limited to file extensions, sizes, thumbnails, modified dates, for the purpose of providing system maintenance, management, and technical support.
    We reserve the rights to suspend your account without notice if the information provided is inaccurate, a breach of community guide lines, country law, overdue balance or unauthorized account activity.
    We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
    This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site. 
    You are solely responsible for the accuracy of Your Data, and Howsmate has no liability whatsoever for errors and omissions in Your Data.
    `
	},
	{
		title: "Modifications to the Service and Price",
		subtitle: [
			"Free Account",
			"Billing",
			"Cancelling Your Upgraded Account Subscription",
			"Terminate Your Account"
		],
		contents: [
			`You have acknowledge that Howsmate reserve the right to change the account structure, price or terminate any variation of account at any time. 

    We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
    We shall not be liable to you or to any third-party for any modification, price change, account structure, suspension or discontinuance of the Service.
    `,
			`Howsmate offers free account option. Howsmate reserve the right to change the free account structure or terminate free account at any time.`,
			`Premium Plans fees for upgraded accounts (Premium, Starter, Popular, Pro or any other variation of upgraded Account planss) will be billed from the date you elect, or convert to, an Upgraded Account and on each renewal thereafter unless and until you cancel your Upgraded Account. Howsmate will automatically bill you by credit card or otherwise if agreed upon each period on the anniversary of the commencement of your Upgraded Account. You acknowledge that the amount billed in any given period may vary for reasons that include, differing amounts due to promotional offers, differing amounts due to changes in your account, or changes in the amount of applicable sales tax, and you authorize us to charge you for such varying amounts. All fees and charges are non-cancelable and non-refundable, and no credits will be given for partially used periods, unless otherwise agreed to.
      If any fee is not paid in a timely manner, or Howsmate is unable to process your transaction using the information provided, Howsmate reserves the right to revoke access to your Howsmate account, downgrade to free account or suspend your account. Howsmate reserves the right to use our discretion to delete some or all of your data in order to prevent incurring continued costs after 30 days of delinquency.
      Howsmate may change the fees and charges in effect, or add new fees and charges from time to time, but we will give you advance notice of these changes by email or on the website. If you want to use a different payment method, you may edit your information by changing it in the account settings under billing information section. If you require assistance or have encountered an error you can contact a support representative via email at support@howsmate.com.my. It is your responsibility to keep your contact information and payment information current and updated. We do not take responsibility of any misuse of credit card ownership for the purpose of refund. 
      `,
			`Howsmate website contains information on how to cancel Your Upgraded Account subcription. If you have purchased a Service for a specific term, such termination will be effective on the last day of the then-current term. Your Order Form may provide that a Renewal Term will begin automatically unless you cancel your Upgraded Account subscription before it renews each month or year in order to avoid billing of the next month’s or year’s fees to your credit card. If You fail to comply with any provision of this Agreement, Howsmate may terminate this Agreement immediately and retain any fees previously paid by You. Upon any termination of this Agreement, You must cease any further use of the Services. If at any time You are not happy with the Services, Your sole remedy is to cease using the Services and follow this termination process, refunds will not be issued on monthly or annual fee due to cancellation. 
      By signing up for a Howsmate Upgraded Account and providing Howsmate with your payment account information, you hereby agree to these payment terms and conditions.
      `,
			`Howsmate website contains information on how to terminate Your Account. You may terminate you account inside settings. If you have purchased a Service for a specific term, such termination will be effective on the last day of the then-current term. Your Order Form may provide that a Renewal Term will begin automatically unless you cancel your Account before it renews each month or year in order to avoid billing of the next month’s or year’s fees to your credit card. If You fail to comply with any provision of this Agreement, Howsmate may terminate this Agreement immediately and retain any fees previously paid by You. Upon any termination of this Agreement, You must cease any further use of the Services. If at any time You are not happy with the Services, Your sole remedy is to cease using the Services and follow this termination process, refunds will not be issued on monthly or annual fee due to cancellation. 
      By signing up for a Howsmate Upgraded Account and providing Howsmate with your payment account information, you hereby agree to these payment terms and conditions.`
		]
	},
	{
		title: "Products or Services",
		contents: `Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
    We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
    We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
    We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
    `
	},
	{
		title: "Accuracy of Billing and Account Information",
		contents: `We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per account, per subscription, per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order or subscription, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
    You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
    `
	},
	{
		title: "Optional Tools",
		contents: `We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
    You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
    Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
    We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
    `
	},
	{
		title: "Third-Party Links",
		contents: `Certain content, products and services available via our Service may include materials from third-parties.
    Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
    We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
    `
	},
	{
		title: "User Comments, Feedback and Other Submissions",
		contents: `If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other digital materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
    We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
    You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libellous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
    COPYRIGHT. You may not post, modify, distribute, or reproduce in any way copyrighted material, trademarks, rights of publicity or other proprietary rights without obtaining the prior written consent of the owner of such proprietary rights. Howsmate may deny access to the Services to any User who is alleged to infringe another party’s copyright. Without limiting the foregoing, if You believe that Your copyright has been infringed, please notify Howsmate via email at support with title “Copyright support” to customercare@howsmate.com.my.
    `
	},
	{
		title: "Personal Information",
		contents: `Your submission of personal information through the store is governed by our Privacy Policy. 
    How we protect your information 
    We will take reasonable technical and organizational precautions to prevent the loss, misuse, theft, unauthorized access, destruction or alteration of Your personal information, non-personal information as well as Files.
    
    We will use all reasonable efforts to safeguard your personal information. However, you should be aware that the use of the Internet is not entirely secure and for this reason We cannot guarantee the security or integrity of any personal information which is transferred from You or to You via the Internet. Should We become aware of an external attack or illegal action, We will notify You.
    
    Some features of the Service allow You to send files, personal information, non-personal information as well as Private Files. By using such features You may share the aforementioned information with other Users, which can read, use and collect that information. Please share your personal information discreetly to other users on Howsmate. You are solely responsible for the information You choose to disclose in such way.
    
    You are entirely responsible for maintaining the security of Your user name and password and agree not to disclose such to any third party.
    `
	},
	{
		title: " Errors, Inaccuracy and Omissions",
		contents: `Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
    We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
    `
	},
	{
		title: "Prohibited Uses",
		contents: `In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content:
    (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information;
    (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
    `
	},
	{
		title: "Disclaimer of Warranties; Limitations of Liability",
		contents: `We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.
    We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
    You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
    You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
    In no case shall Howsmate Technologies, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility.
    Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
    `
	},
	{
		title: "Indemnification",
		contents: `You agree to indemnify, defend and hold harmless Howsmate Technologies and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.`
	},
	{
		title: "Severability",
		contents: `In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.`
	},
	{
		title: "Termination",
		contents: `The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
    These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
    If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
    `
	},
	{
		title: "Entire Agreement",
		contents: `The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.

    These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
    Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
    `
	},
	{
		title: "Governing Law",
		contents: `These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Eee, Ddd, JHR, 12345, Malaysia.
    Any litigation arising from use of Howsmate will be done through Malaysian courts. As with all terms and conditions, use of Howsmate constitutes agreement to same.
    `
	},
	{
		title: "Changes to Terms of Service",
		contents: `You can review the most current version of the Terms of Service at any time at this page.
    We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
    `
	},
	{
		title: "Contact Info",
		contents: `Questions about the Terms of Service should be sent to us at customercare@howsmate.com.my `
	}
];
