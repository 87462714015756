import { FormView } from "../views";
import PropertyForm from "@/components/Forms/PropertyForm/PropertyForm.vue";
import PropertySection from "@/components/Forms/Property/PropertySection.vue";
import AvailSection from "@/components/Forms/Availability/AvailSection.vue";
import RentSection from "@/components/Forms/Rent/RentSection.vue";
import FeatSection from "@/components/Forms/Features/FeatSection.vue";
import PrefSection from "@/components/Forms/Preference/PrefSection.vue";
import PhotoSection from "@/components/Forms/Photo/PhotoSection.vue";
import HousemateForm from "@/components/Forms/HousemateForm/HousemateForm.vue";
import AboutSection from "@/components/Forms/HousemateForm/About/AboutSection.vue";
import AvailabilitySection from "@/components/Forms/HousemateForm/Availability/AvailabilitySection.vue";
import PreferenceSection from "@/components/Forms/HousemateForm/Preference/PreferenceSection.vue";
import StatusSection from "@/components/Forms/HousemateForm/Status/StatusSection.vue";
import HPhotoSection from "@/components/Forms/HousemateForm/Photo/PhotoSection.vue";

export default [
	{
		path: "/form",
		name: "form",
		meta: { requiresAuth: true },
		component: FormView,
		children: [
			{
				path: "property",
				name: "property-form",
				meta: { requiresAuth: true },
				component: PropertyForm,
				children: [
					{
						path: "property",
						name: "property-form-property",
						meta: { requiresAuth: true },
						component: PropertySection
					},
					{
						path: "availability",
						name: "property-form-availability",
						meta: { requiresAuth: true },
						component: AvailSection
					},
					{
						path: "rent",
						name: "property-form-rent",
						meta: { requiresAuth: true },
						component: RentSection
					},
					{
						path: "features",
						name: "property-form-features",
						meta: { requiresAuth: true },
						component: FeatSection
					},
					{
						path: "preference",
						name: "property-form-preference",
						meta: { requiresAuth: true },
						component: PrefSection
					},
					{
						path: "photo",
						name: "property-form-photo",
						meta: { requiresAuth: true },
						component: PhotoSection
					}
				]
			},
			{
				path: "housemate",
				name: "housemate-form",
				meta: { requiresAuth: true },
				component: HousemateForm,
				children: [
					{
						path: "about",
						name: "housemate-form-about",
						meta: { requiresAuth: true },
						component: AboutSection
					},
					{
						path: "availability",
						name: "housemate-form-availability",
						meta: { requiresAuth: true },
						component: AvailabilitySection
					},
					{
						path: "preference",
						name: "housemate-form-preference",
						meta: { requiresAuth: true },
						component: PreferenceSection
					},
					{
						path: "status",
						name: "housemate-form-status",
						meta: { requiresAuth: true },
						component: StatusSection
					},
					{
						path: "photo",
						name: "housemate-form-photo",
						meta: { requiresAuth: true },
						component: HPhotoSection
					}
				]
			}
		]
	}
];
