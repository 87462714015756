import { defineStore } from "pinia";
import api from "@/api";
import { from } from "linq-to-typescript";
import type IdNameModel from "@/api/models/IdNameModel";
import { usePropConstStore } from "@/stores/propertyConstStore";
import type { HousematePreferenceTypes } from "@/enums/HousematePreferenceTypes";
import { PromptTypes } from "@/enums/PromptTypes";
import { useGlobalStore } from "@/stores/globalStore";

export const useHousemateConstStore = defineStore({
	id: "housemateConstStore",
	state: () => ({
		lookingForPlaceData: [] as IdNameModel[],
		durationOfStayData: [] as IdNameModel[],
		preferencesData: [] as IdNameModel[],
		employmentStatusData: [] as IdNameModel[],
		promptTitleData: [] as IdNameModel[]
	}),
	getters: {
		lookingForPlace: (state) => state.lookingForPlaceData,
		durationOfStay: (state) => state.durationOfStayData,
		preferences: (state) => state.preferencesData,
		employmentStatus: (state) => state.employmentStatusData,
		promptTitle: (state) => state.promptTitleData
	},
	actions: {
		getId(enums: IdNameModel[], name: string) {
			return from(enums).first(
				(x) => x.name.toLowerCase() === name.toLowerCase()
			).id;
		},
		getPreferenceId(name: string, type: HousematePreferenceTypes) {
			return from(this.preferencesData).first(
				(x: any) =>
					x.name.toLowerCase() === name.toLowerCase() &&
					x.type == type
			).id;
		},
		loadLookingForPlace() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.lookingForPlaceData.length === 0)
					api.getHmLookingForPlace().then((res) => {
						this.lookingForPlaceData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id
								} as IdNameModel)
						);
						resolve(this.lookingForPlaceData);
					});
				else resolve(this.lookingForPlaceData);
			});
		},
		loadEmploymentStatus() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.employmentStatusData.length === 0)
					api.getHmEmploymentStatus().then((res) => {
						this.employmentStatusData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id
								} as IdNameModel)
						);
						resolve(this.employmentStatus);
					});
				else resolve(this.employmentStatus);
			});
		},
		loadDurationOfStay() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.durationOfStayData.length === 0) {
					const propConst = usePropConstStore();
					propConst.loadDurationOfStay().then((x) => {
						this.durationOfStayData = x;
						resolve(this.durationOfStay);
					});
				} else resolve(this.durationOfStay);
			});
		},
		loadPreferences() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.preferencesData.length === 0)
					api.getHmPreferences().then((res) => {
						this.preferencesData = res.data.data.map(
							(r: any) =>
								({
									name: r.DisplayName,
									id: r.id,
									type: r.Type.Name,
									order: r.Order
								} as IdNameModel)
						);
						resolve(this.preferences);
					});
				else resolve(this.preferences);
			});
		},
		loadPromptTitle() {
			return new Promise<IdNameModel[]>((resolve) => {
				if (this.promptTitleData.length === 0)
					api.getPromptTitle().then((res) => {
						this.promptTitleData = res.data.data
							.filter(
								(x: any) => x.Type === PromptTypes.Housemate
							)
							.map(
								(r: any) =>
									({
										name: r.DisplayName,
										id: r.id,
										show: r.Show,
										placeholder: r.Placeholder
									} as IdNameModel)
							);
						resolve(this.promptTitle);
					});
				else resolve(this.promptTitle);
			});
		},
		async loadAll() {
			const loads = [
				this.loadEmploymentStatus,
				this.loadPromptTitle,
				this.loadPreferences,
				this.loadDurationOfStay,
				this.loadLookingForPlace
			];
			loads.forEach(async (load) => {
				await load();
				useGlobalStore().setPercent(1);
			});
		}
	}
});
