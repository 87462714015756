import { defineStore } from "pinia";
export const useFavoriteStore = defineStore({
	id: "favoriteStore",
	state: () => ({
		propertyFavorites: [] as number[],
		housemateFavorites: [] as number[]
	}),
	getters: {},
	actions: {
		setPropFavorites(id: number) {
			const ids = [...this.propertyFavorites, id];
			this.propertyFavorites = [...new Set(ids)];
		},
		setHouseFavorites(id: number) {
			const ids = [...this.housemateFavorites, id];
			this.housemateFavorites = [...new Set(ids)];
		},
		filterPropFavorites(id: number) {
			this.propertyFavorites = this.propertyFavorites.filter(
				(fav) => fav !== id
			);
			return this.propertyFavorites;
		},
		filterHouseFavorites(id: number) {
			this.housemateFavorites = this.housemateFavorites.filter(
				(fav) => fav !== id
			);
			return this.housemateFavorites;
		}
	},
	persist: {
		paths: ["propertyFavorites", "housemateFavorites"]
	}
});
