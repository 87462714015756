import ChildRoute from "@/components/common/ChildRoute.vue";
import {
	PaymentPlanView,
	PaymentFormView,
	PaymentPostView,
	PaymentExploreView,
	PaymentCancelView
} from "@/views";
import CancelSubs from "@/components/Payment/CancelSubs.vue";
import FeedbackSubs from "@/components/Payment/FeedbackSubs.vue";
import { RouteLocationNormalized } from "vue-router";

export default [
	{
		path: "/payment",
		name: "payment",
		meta: { requiresAuth: true },
		component: ChildRoute,
		children: [
			{
				path: "plan",
				name: "payment-plan",
				component: PaymentPlanView
			},
			{
				path: "form/:plan",
				name: "payment-form",
				component: PaymentFormView,
				beforeEnter: (to: RouteLocationNormalized) => {
					if (to.params.plan == "Free")
						return { path: "/payment/cancel" };
					else return true;
				}
			},
			{
				path: "post/:on",
				name: "payment-post",
				component: PaymentPostView
			},
			{
				path: "explore/:plan",
				name: "payment-explore",
				component: PaymentExploreView
			},
			{
				path: "cancel",
				name: "payment-cancel",
				component: PaymentCancelView,
				children: [
					{
						path: "",
						name: "payment-cancel-feedback",
						component: FeedbackSubs
					},
					{
						path: ":on",
						name: "payment-cancel-post",
						component: CancelSubs,
						beforeEnter: (to: RouteLocationNormalized) => {
							if (to.params.on !== "success") return false;
							else return true;
						}
					}
				]
			}
		]
	}
];
