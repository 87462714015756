import { defineStore } from "pinia";
import type { PromptModel } from "@/models/property-form/PromptModel";
import api from "@/api";
import { useHousemateConstStore } from "@/stores/housemateConstStore";
import { usePropConstStore } from "@/stores/propertyConstStore";
import { HousematePreferenceTypes } from "@/enums/HousematePreferenceTypes";
import { useGlobalStore } from "@/stores/globalStore";
import { dataUrlToFile, formatISODate } from "@/helper/Utilities";

export const useHousemateFormStore = defineStore({
	id: "housemateFormStore",
	state: () => ({
		id: 0,
		isEdit: false,
		user: {
			id: "",
			mobile: ""
		},
		about: {
			lookingFor: "own purpose",
			name: "",
			age: "",
			gender: "Male",
			maxBudget: ""
		},
		availability: {
			movingDate: formatISODate(new Date()),
			duration: "6 months",
			lookingAtSelected: [] as string[]
		},
		preference: {
			room: "flexible",
			furnishing: "flexible",
			bathroom: "flexible",
			internet: "flexible",
			parking: "requires",
			numOfHousemates: "flexible",
			teamingUp: "yes"
		},
		status: {
			employment: "student",
			description: "",
			prompts: [] as PromptModel[]
		},
		photo: ""
	}),
	getters: {
		getPhoto: (state) => state.photo
	},
	actions: {
		clear() {
			localStorage.removeItem("housemateFormStore");
			this.$reset();
		},
		async uploadPhoto() {
			if (this.photo.startsWith("data:")) {
				const newPhoto = await dataUrlToFile(this.photo, "profile");
				return await api.postHousematePhoto(newPhoto).finally(() => {
					useGlobalStore().setPercent(1);
				});
			} else return this.photo;
		},
		validateForm() {
			const nameValid = this.about.name.length > 0;
			const ageValid = this.about.age.length > 18;
			const maxBudgetValid = this.about.maxBudget.length > 0;
			const lookingAtValid =
				this.availability.lookingAtSelected.length > 0;
			return nameValid && ageValid && maxBudgetValid && lookingAtValid;
		},
		async buildJson(id?: number) {
			const pcs = usePropConstStore();
			const hcs = useHousemateConstStore();
			useGlobalStore().setTotalPercent(
				Object.keys(hcs.$state).length + 3
			);
			await pcs.loadArea().finally(() => {
				useGlobalStore().setPercent(1);
			});
			await hcs.loadAll();

			const photoId = await this.uploadPhoto();

			const lookingForPlaceId = hcs.getId(
				hcs.lookingForPlace,
				this.about.lookingFor
			);
			const durationOfStayId = hcs.getId(
				hcs.durationOfStay,
				this.availability.duration
			);
			const employmentStatusId = hcs.getId(
				hcs.employmentStatus,
				this.status.employment
			);

			const selectedAreasIds = this.availability.lookingAtSelected.map(
				(x: any) => ({
					Housemate_id: "+",
					Area_id: {
						id: pcs.getId(pcs.areas, x)
					}
				})
			);

			const preferences = [
				{
					Housemate_id: "+",
					HmPreference_id: {
						id: hcs.getPreferenceId(
							this.preference.room,
							HousematePreferenceTypes.Room
						)
					}
				},
				{
					Housemate_id: "+",
					HmPreference_id: {
						id: hcs.getPreferenceId(
							this.preference.furnishing,
							HousematePreferenceTypes.Furnishing
						)
					}
				},
				{
					Housemate_id: "+",
					HmPreference_id: {
						id: hcs.getPreferenceId(
							this.preference.bathroom,
							HousematePreferenceTypes.Bathroom
						)
					}
				},
				{
					Housemate_id: "+",
					HmPreference_id: {
						id: hcs.getPreferenceId(
							this.preference.internet,
							HousematePreferenceTypes.Internet
						)
					}
				},
				{
					Housemate_id: "+",
					HmPreference_id: {
						id: hcs.getPreferenceId(
							this.preference.parking,
							HousematePreferenceTypes.Parking
						)
					}
				},
				{
					Housemate_id: "+",
					HmPreference_id: {
						id: hcs.getPreferenceId(
							this.preference.numOfHousemates,
							HousematePreferenceTypes.NumberOfHousemates
						)
					}
				},
				{
					Housemate_id: "+",
					HmPreference_id: {
						id: hcs.getPreferenceId(
							this.preference.teamingUp,
							HousematePreferenceTypes.TeamingUp
						)
					}
				}
			];

			const prompts = this.status.prompts.map((x: any) => ({
				Prompt_id: {
					Title: hcs.getId(hcs.promptTitle, x.title),
					Text: x.value
				}
			}));

			const rawJson = {
				LookingForPlace: lookingForPlaceId,
				Name: this.about.name.trim(),
				Age: this.about.age,
				Gender: this.about.gender,
				MaxBudget: this.about.maxBudget,
				MovingDate: new Date(
					this.availability.movingDate
				).toISOString(),
				DurationOfStay: durationOfStayId,
				SelectedAreas: {
					create: selectedAreasIds,
					update: [],
					delete: []
				},
				Preferences: {
					create: preferences,
					update: [],
					delete: []
				},
				EmploymentStatus: employmentStatusId,
				Description: this.status.description.trim(),
				Prompts: {
					create: prompts,
					update: [],
					delete: []
				},
				Photo: photoId
			};

			const json = id
				? JSON.stringify({ id, ...rawJson })
				: JSON.stringify(rawJson);
			return await api
				.postHousemateForm(json)
				.then((res) => {
					useGlobalStore().setPercent(1);
					return res;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		async getHousemateById(id: number | string) {
			this.clear();
			const response = await api.getHousemateListingById(id);
			const data = response.data.data;

			// user_created
			this.$state.user.id = data.user_created.id;
			this.$state.user.mobile = data.user_created.mobile_number;

			// id
			this.$state.id = data.id;
			// about
			this.$state.about.age = data.Age.toString();
			this.$state.about.lookingFor =
				data.LookingForPlace.DisplayName.toLowerCase();
			this.$state.about.name = data.Name;
			this.$state.about.gender = data.Gender;
			this.$state.about.maxBudget = data.MaxBudget.toString();

			// availability
			const date = new Date(data.MovingDate);
			this.$state.availability.movingDate = `${date.getFullYear()}-${
				date.getMonth() + 1
			}-${date.getDate()}`;
			this.$state.availability.duration =
				data.DurationOfStay.DisplayName.toLowerCase();
			this.$state.availability.lookingAtSelected = data.SelectedAreas.map(
				(x: any) => x.Area_id.Name
			);

			// preference
			this.$state.preference.room = data.Preferences.filter(
				(x: any) =>
					x.HmPreference_id.Type.Name == HousematePreferenceTypes.Room
			)
				.map((x: any) => x.HmPreference_id.DisplayName)[0]
				.toLowerCase();
			this.$state.preference.furnishing = data.Preferences.filter(
				(x: any) =>
					x.HmPreference_id.Type.Name ==
					HousematePreferenceTypes.Furnishing
			)
				.map((x: any) => x.HmPreference_id.DisplayName)[0]
				.toLowerCase();
			this.$state.preference.bathroom = data.Preferences.filter(
				(x: any) =>
					x.HmPreference_id.Type.Name ==
					HousematePreferenceTypes.Bathroom
			)
				.map((x: any) => x.HmPreference_id.DisplayName)[0]
				.toLowerCase();
			this.$state.preference.internet = data.Preferences.filter(
				(x: any) =>
					x.HmPreference_id.Type.Name ==
					HousematePreferenceTypes.Internet
			)
				.map((x: any) => x.HmPreference_id.DisplayName)[0]
				.toLowerCase();
			this.$state.preference.parking = data.Preferences.filter(
				(x: any) =>
					x.HmPreference_id.Type.Name ==
					HousematePreferenceTypes.Parking
			)
				.map((x: any) => x.HmPreference_id.DisplayName)[0]
				.toLowerCase();
			this.$state.preference.numOfHousemates = data.Preferences.filter(
				(x: any) =>
					x.HmPreference_id.Type.Name ==
					HousematePreferenceTypes.NumberOfHousemates
			)
				.map((x: any) => x.HmPreference_id.DisplayName)[0]
				.toLowerCase();
			this.$state.preference.teamingUp = data.Preferences.filter(
				(x: any) =>
					x.HmPreference_id.Type.Name ==
					HousematePreferenceTypes.TeamingUp
			)
				.map((x: any) => x.HmPreference_id.DisplayName)[0]
				.toLowerCase();

			// status
			this.$state.status.employment =
				data.EmploymentStatus.DisplayName.toLowerCase();
			this.$state.status.description = data.Description;
			this.$state.status.prompts = data.Prompts.map((x: any) => ({
				title: x.Prompt_id.Title.DisplayName,
				value: x.Prompt_id.Text,
				description: "",
				show: x.Prompt_id.Title.Show
			}));

			this.$state.photo = data.Photo.id;
		},
		async deleteHousemateById(id: number, noClear?: boolean) {
			await api.deleteHousemateById(id).finally(() => {
				if (!noClear) this.clear();
			});
		},
		async updateActive(id: number, value: boolean) {
			const body = {
				Active: value
			};
			await api.patchHousemateById(id, body);
		}
	},
	persist: true
});
