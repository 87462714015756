import {onMounted} from "vue";
import {useRoute} from "vue-router";
import router from "@/router";
import api from "@/api";

export default {
  setup() {
    onMounted(() => {
      const route = useRoute();
      const token = route.params.id;
      api.getTokenVerify(token.toString(), true).then(() => {
        router.push("/login");
      });
    })
  }
}