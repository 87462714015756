import BaseModel from "@/api/models/BaseModel";

export default class Login extends BaseModel {
    private email:string;
    private password:string;
    private mode:string;

    constructor(email:string, password:string) {
        super();
        this.email = email;
        this.password = password;
        this.mode = 'cookie';
    }
}