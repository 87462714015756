export const upperLower = /^(?=.*[A-Z])(?=.*[a-z])/g;
export const letterNumber = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/g;
export const specialChar = /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹])/g;
export const passLength = /^.{12,}$/;
export const stripePrefix = {
	paymentIntent: "pi_",
	setupIntent: "seti_"
};
export default {
	avatar: "88f1cf48-762a-4608-860d-f82cf9f0adb2",
	cdnUrl: "https://files.howsmate.com.my/",
	baseUrl:
		process.env.NODE_ENV === "production"
			? "https://howsmate.com.my"
			: "https://odev.howsmate.com.my",
	emailRule: /.+@\w+\.\w+(\.\w)?/g,
	passRule:
		/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).{12,}$/g
};
