import {
	mdiMessageTextOutline,
	mdiStarCircleOutline,
	mdiStarOutline,
	mdiThumbUpOutline
} from "@mdi/js";
export default [
	{
		type: "Free",
		pricing: {
			amount: 0
		},
		click: {
			icon: mdiMessageTextOutline,
			description: "Contact Free to Chat and receive all messages ",
			label: "Be Free"
		},
		perks: [
			"1 Property Listings",
			"No rotation",
			"Free to message after 10 days",
			"See property ads number",
			"-",
			"-"
		],
		activeLimit: 1
	},
	{
		type: "Starter",
		pricing: {
			amount: 48,
			renewal: 14
		},
		click: {
			icon: mdiThumbUpOutline,
			description:
				"Rank higher in the search result, message anyone, see all available numbers.",
			label: "Be a Starter"
		},
		perks: [
			"2  Property Listings",
			"Rotation every 5 days",
			`Instantly message anyone`,
			"See all available numbers",
			"-",
			"-"
		],
		activeLimit: 2
	},
	{
		type: "Popular",
		pricing: {
			amount: 68,
			renewal: 30,
			highlight: "best value"
		},
		click: {
			icon: mdiStarOutline,
			description:
				"Rank higher in the search result, message anyone, see all available numbers.",
			label: "Be Popular"
		},
		perks: [
			"3  Property Listings",
			"Rotation every 5 days",
			`Instantly message anyone`,
			"See all available numbers",
			"Make appointments",
			"-"
		],
		activeLimit: 3
	},
	{
		type: "Pro",
		pricing: {
			amount: 98,
			renewal: 30
		},
		click: {
			icon: mdiStarCircleOutline,
			description:
				"Rank higher in the search result, message anyone, see all available numbers.",
			label: "Be a Pro"
		},
		perks: [
			"5  Property Listings",
			"Rotation every 6 days",
			`Instantly message anyone`,
			"See all available numbers",
			"Make appointments",
			"On demand listings"
		],
		activeLimit: 5
	}
];
