export default {
	amount: [
		"1 room",
		"2 rooms",
		"3 rooms",
		"4 rooms",
		"5 rooms",
		"6 rooms",
		"7 rooms",
		"8 rooms"
	],
	features: [
		"Study table",
		"Chair",
		"Shelf",
		"Cupboard",
		"Sofa",
		"Bedside table",
		"Table lamp",
		"Air conditioner",
		"Fan",
		"Door lock",
		"Balcony",
		"Kitchenette"
	]
};
