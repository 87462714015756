import {
	DashboardSetView,
	DashboardProfileView,
	DashboardEditView,
	DashboardSettingsView
} from "../views";
import {
	SettingsPlan,
	SettingsSupport,
	SettingsLanguage,
	SettingsPreferences,
	OptionList
} from "@/components/Dashboard/Settings";
import ChildRoute from "@/components/common/ChildRoute.vue";
import PlanPurchases from "@/components/Dashboard/Settings/Plan/PlanPurchases.vue";
import PlanMembership from "@/components/Dashboard/Settings/Plan/PlanMembership.vue";
import MembershipUpdate from "@/components/Dashboard/Settings/Plan/Membership/MembershipUpdate.vue";
import PrefInfo from "@/components/Dashboard/Settings/Preferences/PrefInfo.vue";
import PrefNotifications from "@/components/Dashboard/Settings/Preferences/PrefNotifications.vue";
import PrefSecurity from "@/components/Dashboard/Settings/Preferences/PrefSecurity.vue";
import SecurityPassword from "@/components/Dashboard/Settings/Preferences/Security/SecurityPassword.vue";
import SecurityDelete from "@/components/Dashboard/Settings/Preferences/Security/SecurityDelete.vue";
import CancelPassword from "@/components/Dashboard/Settings/Plan/Membership/Cancel/CancelPassword.vue";
import CancelConfirm from "@/components/Dashboard/Settings/Plan/Membership/Cancel/CancelConfirm.vue";
import CancelReason from "@/components/Dashboard/Settings/Plan/Membership/Cancel/CancelReason.vue";
import { usePropListingStore } from "@/stores/propertyListingStore";
import { clearLocalStorage } from "@/helper/Utilities";

export default [
	{
		path: "/dashboard/set",
		name: "set-dashboard",
		meta: { requiresAuth: true },
		component: DashboardSetView,
		beforeEnter: (to: any) => {
			if (!usePropListingStore().isEdit) clearLocalStorage();
			return true;
		}
	},
	{
		path: "/dashboard/profile",
		name: "profile-dashboard",
		meta: { requiresAuth: true },
		component: DashboardProfileView
	},
	{
		path: "/dashboard/edit",
		name: "edit-dashboard",
		meta: { requiresAuth: false },
		component: DashboardEditView
	},
	{
		path: "/dashboard/settings",
		name: "settings-dashboard",
		meta: { requiresAuth: true },
		component: DashboardSettingsView,
		children: [
			{
				path: "",
				name: "settings-root",
				meta: { requiresAuth: false },
				component: OptionList
			},
			{
				path: "plan",
				name: "settings-plan",
				meta: { requiresAuth: false },
				component: ChildRoute,
				children: [
					{
						path: "",
						name: "settings-plan-detail",
						meta: { requiresAuth: false },
						component: SettingsPlan
					},
					{
						path: "purchases",
						name: "settings-plan-purchases",
						meta: { requiresAuth: false },
						component: PlanPurchases
					},
					{
						path: "membership",
						name: "settings-plan-membership",
						meta: { requiresAuth: false },
						component: ChildRoute,
						children: [
							{
								path: "",
								name: "settings-plan-membership-detail",
								meta: { requiresAuth: false },
								component: PlanMembership
							},
							{
								path: "update",
								name: "settings-plan-membership-update",
								meta: { requiresAuth: false },
								component: ChildRoute,
								children: [
									{
										path: "",
										name: "settings-plan-membership-update-detail",
										meta: { requiresAuth: false },
										component: MembershipUpdate
									},
									{
										path: "cancel",
										name: "settings-plan-membership-update-cancel",
										meta: { requiresAuth: false },
										component: CancelPassword
									},
									{
										path: "confirm",
										name: "settings-plan-membership-update-confirm",
										meta: { requiresAuth: false },
										component: CancelConfirm
									},
									{
										path: "reason",
										name: "settings-plan-membership-update-reason",
										meta: { requiresAuth: false },
										component: CancelReason
									}
								]
							}
						]
					}
				]
			},
			{
				path: "language",
				name: "settings-language",
				meta: { requiresAuth: false },
				component: SettingsLanguage
			},
			{
				path: "preference",
				name: "settings-preference",
				meta: { requiresAuth: false },
				component: ChildRoute,
				children: [
					{
						path: "",
						name: "settings-preference-detail",
						meta: { requiresAuth: false },
						component: SettingsPreferences
					},
					{
						path: "info",
						name: "settings-preference-info",
						meta: { requiresAuth: false },
						component: PrefInfo
					},
					{
						path: "notification",
						name: "settings-preference-notification",
						meta: { requiresAuth: false },
						component: PrefNotifications
					},
					{
						path: "security",
						name: "settings-preference-security",
						meta: { requiresAuth: false },
						component: ChildRoute,
						children: [
							{
								path: "",
								name: "settings-preference-security-detail",
								meta: { requiresAuth: false },
								component: PrefSecurity
							},
							{
								path: "password",
								name: "settings-preference-security-password",
								meta: { requiresAuth: false },
								component: SecurityPassword
							},
							{
								path: "delete",
								name: "settings-preference-security-delete",
								meta: { requiresAuth: false },
								component: SecurityDelete
							}
						]
					}
				]
			},
			{
				path: "support",
				name: "settings-support",
				meta: { requiresAuth: false },
				component: SettingsSupport
			}
		]
	}
];
